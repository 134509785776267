<template>
    <div class="xc_banner">
        <div class="img">
            <!--<img :src="imgUrl" alt="">-->
            <el-image v-if="!source || !mobileHide" :src="$SYS.IMG_PREFIX + imgUrl">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                    <span>暂无图片</span>
                </div>
            </el-image>
            <video v-if="mobileHide && source" autoplay="true" loop="true" id="video" poster="" muted="">
                <source :src="$SYS.IMG_PREFIX + source" type="video/mp4">
            </video>
        </div>
        <div class="content">
            <div class="wrap">
                <div class="title">{{ name }}</div>
                <div class="crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{name: 'Home' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item v-for="crumb in $route.matched.slice(0, $route.matched.length - 2)" :key="crumb.name" :to="{name: crumb.name}">
                            {{ crumb.name }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "xcBanner",
    props: ['name', 'bannerId'],
    data(){
        return {
            imgUrl:'',
            source:'',
            mobileHide: true,
        }
    },
    methods: {},
    created() {
        this.$http.get( `${this.$API.getBannerByChannelId}?id=${this.bannerId}`).then((res) => {
            if(res.data.code === 200){
                if(res.data.result.length > 0){
                    this.imgUrl = res.data.result[0].img
                    this.source = res.data.result[0].source
                }
            }
        })
        let baseType = this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC
        if (!baseType) {
            this.mobileHide = false
        }
    },
}
</script>

<style lang="less">
@color: #51b953;
.xc_banner {
    position: relative;
    width:100%;
    height:550px;
    overflow:hidden;
    .img {
        img,video {
            position:absolute;
            width:100%;
            max-width:none;
            height:auto;
            top:0;
            left:50%;
            transform: translateX(-50%);
        }
    }

    .content {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 70px;
        z-index: 1;

        .title {
            font-size: 50px;
            color: #fff;
            position: relative;
            padding-left: 40px;
            margin-bottom: 90px;
            line-height: 1;
            text-transform: capitalize;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                height: 100%;
                width: 5px;
                background: @color;
                top: 50%;
                transform: translateY(-50%);

            }
        }
    }

    .el-breadcrumb__item:first-child .el-breadcrumb__inner.is-link {
        color: @color;
    }

    .el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover {
        color: @color;
    }

    .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
        color: #ffffff;
        font-weight: normal;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
        color: #fff;
    }
}
@media only screen and (max-width: 1920px) {
    .xc_banner{
        .img{
            img, video{
                width:1920px;
            }
        }
    }

}
@media only screen and (max-width: 1366px) {
    .xc_banner{
        height:450px;
        .img {
            img, video{width:1600px;}
        }
    }
    .xc_banner .content {
        .title {
            font-size: 40px;
            margin-bottom: 5%;

            &:after {
                height: 100%;
                top: 0;
                margin-top: 0;
            }
        }
    }

}

@media only screen and (max-width: 992px) {
    .xc_banner{
        height:370px;
        .img {
            img, video{width:1300px;}
        }
    }
    .xc_banner .content {
        bottom: 20px;
    }
}

@media only screen and (max-width: 760px) {
    .xc_banner{
        display:none;
        height:200px;
        .img {
            img, video{width:900px;}
        }
    }
    .xc_banner .content {
        .title {
            font-size: 20px;
            padding-left: 20px;
        }

        .crumbs {
            display: none;
        }
    }
}
</style>
<style lang="less">
.xc_banner{
    .el-image{
        width:100%;
        position:relative;
        height:550px;
        .image-slot{
            position:absolute;
            top:45%;
            width:100%;
            text-align: center;
            i{
                font-size: 30px;
                vertical-align: middle;
            }
            span{
                margin-left: 20px;
                font-size: 16px;
                vertical-align: middle;
            }
        }
    }
}
</style>
