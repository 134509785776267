<template>
    <div class="products">
        <xc-banner :banner-id="id" :name="name"/>
        <div class="product01">
            <div class="wrap">
                <div class="title">
                    <div class="tit">{{ title.title }}</div>
                    <div class="con">{{ title.summary }}</div>
                </div>
                <div class="content fix">
                    <div class="right r">
                        <div class="protype">
                            <div class="select">
                                <div class="info" v-if="!noChild">PRODUCTS CATEGORY</div>
                                <div class="erji">
                                    <el-collapse accordion v-model="activeName">
                                        <el-collapse-item v-if="!noChild" v-for="(item,index) in erjiList" :key="index" :name="item.id">
                                            <template slot="title">
                                                <div class="title" :class="[item.id === checkCatId ? 'active' : '']" @click="changePro(item)">
                                                    <span v-if="item.childList && item.childList.length > 0" class="icon">
                                                        <i class="iconfont jia icon-jiahao1"></i>
                                                        <i class="iconfont jian icon-jianhao"></i>
                                                    </span>
                                                    <span>{{ item.enName }}</span>
                                                </div>
                                            </template>
                                            <div class="sanji" :class="[sanji.id === checkCatId ? 'active' : '']" v-for="(sanji,index) in item.childList" :key="index" @click="changePro(sanji)" v-if="item.childList && item.childList.length > 0">
                                                {{ sanji.enName }}
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </div>
                            </div>
                            <div class="Brochures">
                                <div class="tit">
                                    Brochures
                                </div>
                                <div class="con">
                                    View our 2021 financial prospectus brochure for an ease to read guide on all of the services offers
                                </div>
                                <div class="btn" @click="$router.push({path:'/about',query:{id:'1483421350510473218'}})">COMPANY INFO</div>
                                <div class="btn" @click="$router.push({path:'/contact',query:{id:'1483421481972543490'}})">CONTACT</div>
                            </div>
                        </div>
                    </div>
                    <div class="left l">
                        <div class="fix">
                            <div v-for="(item,i) in productList" :key="i" class="item" @click="toDetail(item)">
                                <div class="img imgZoom">
                                    <div class="rect-100">
                                        <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                                    </div>
                                </div>
                                <div class="tit">{{ item.name }}</div>
                            </div>
                        </div>
                        <div class="load" v-if="showViewMore">
                            <div class="view_more" @click="LoadMore">VIEW MORE</div>
                        </div>
                        <div class="noProduct" v-if="noProduct">
                            <a-empty/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in infoList" :key="index">
            <pro-text :item="item" :proAlign="index / 2 === 0 ? 'img-left' : ''"/>
        </div>
        <div class="product04">
            <to-contact/>
        </div>
    </div>
</template>

<script>
import XcBanner from "/src/components/xcBanner";
import ProType from "/src/components/ProType";
import ProText from "/src/components/ProText";
import ToContact from "/src/components/ToContact";

export default {
    name: "Valves",
    components: {ToContact, ProText, ProType, XcBanner},
    data() {
        return {
            catId: this.$route.query.catId,
            id: this.$route.query.id,
            checkCatId: this.$route.query.childCatId,
            noChild: this.$route.query.noChild,
            name: this.$route.query.name,
            // checkCatIdArr: this.$route.query.childCatId, // 要选中的产品分类Id
            title: {},
            pageNo: 1,
            pageSize: 9,
            showViewMore: true,
            productList: [],
            erjiList: [],
            infoList: [],
            noProduct: false,
            activeName: null,
            routePrefix: '/industries'
        }
    },
    created() {
        this.routePrefix = '/' + this.$route.path.split('/')[1]
        this.getPageByChannelId(this.id);

        if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
            this.getProductCatList(true)
        } else {
            this.getCraftCatList(true)
        }
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'


    },
    methods: {
        //通过栏目id 获取页面内容
        getPageByChannelId(id) {
            this.$http.get(`${this.$API.getPageByChannelId}?channelId=${id}`).then(res => {
                if (res.data.code === 200) {
                    if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
                        if (res.data.result.length > 0) {
                            this.title = res.data.result[0]
                            this.infoList = res.data.result.slice(1, 3)
                        }
                    } else {
                        if (this.noChild && res.data.result.length > 8) {
                            this.title = res.data.result[9] || {}
                            this.infoList = res.data.result.slice(7, 9)

                        } else if (res.data.result.length > 4) {
                            this.title = res.data.result[5] || {}
                            this.infoList = res.data.result.slice(2, 4)
                        }
                    }

                }
            });
        },
        //通过产品分类id 查询产品分类
        getProductCatList(isFirst) {
            this.$http.get(`${this.$API.getProductCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.length > 0) {
                        this.erjiList = res.data.result[0].childList
                        for (let erji in this.erjiList) {
                            if (erji.id === this.checkCatId) {
                                this.activeName = erji.id;
                                break;
                            }
                            if (erji.childList) {
                                for (let sanji in erji.childList) {
                                    if (sanji.id === this.checkCatId) {
                                        this.activeName = erji.id;
                                        break;
                                    }
                                }
                            }
                        }
                        if (isFirst) {
                            if (this.erjiList && this.erjiList.length > 0) {
                                let firstId = this.erjiList[0].childList ? this.erjiList[0].childList[0].id : this.erjiList[0].id;
                                this.checkCatId = this.checkCatId || firstId;
                                this.activeName = this.activeName || this.erjiList[0].id;
                                this.getProductListByCat();
                            }

                        }
                    }
                }
            });
        },
        //同过产品分类来查询 产品
        getProductListByCat() {
            this.$http.get(this.$API.getProductListByCat, {params: {'catId': this.checkCatId, 'pageNo': this.pageNo, 'pageSize': this.pageSize}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.noProduct = false
                        this.productList.push(...res.data.result.records)
                        if (res.data.result.pages === this.pageNo) {
                            this.showViewMore = false
                            return;
                        }
                        this.showViewMore = true
                        this.pageNo++;
                    } else {
                        this.showViewMore = false
                        this.noProduct = true
                    }

                }
            });
        },
        //点击产品跳转到产品详情页面
        toDetail(item) {
            this.$router.push({path: this.routePrefix + `/${this.id}/${this.catId}/${item.id}/${this.checkCatId}/${item.name.replace(/\//g, "")}`, query: {noChild: this.noChild}})
            // this.$router.push({name: 'Prodetail', params: { detail: item}});
        },
        //点击产品分类查询对应的产品列表
        changePro(item) {
            this.checkCatId = item.id
            this.getPageByChannelId(item.channelId)
            if (!item.childList || item.childList.length < 0) {
                this.pageNo = 1;
                this.productList = []
                if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
                    this.getProductListByCat(item.id)
                } else {
                    this.name = item.enName
                    this.getCraftListByCat(item.id)
                    document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
                }
            }

        },
        //通过工艺——产品分类id 查询产品分类
        getCraftCatList(isFirst) {
            this.$http.get(`${this.$API.getCraftCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.length > 0) {
                        let hasChild = !!res.data.result[0].childList
                        this.erjiList = hasChild ? res.data.result[0].childList : res.data.result[0]
                        if (hasChild) {
                            for (let erji in this.erjiList) {
                                if (erji.id === this.checkCatId) {
                                    this.activeName = erji.id;
                                    break;
                                }
                                if (erji.childList) {
                                    for (let sanji in erji.childList) {
                                        if (sanji.id === this.checkCatId) {
                                            this.activeName = erji.id;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        //获取工艺栏目下层接口
                        this.$http.get(this.$API.getChannelList).then((res) => {
                            if (res.data.code === 200) {
                                let craftList = res.data.result[1].childList[0].childList
                                for (let item of this.erjiList) {
                                    for (let channelId of craftList) {
                                        if (item.id === channelId.url.split('#')[1]) {
                                            item.channelId = channelId.id
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        if (isFirst) {
                            this.checkCatId = this.checkCatId || (this.erjiList[0].childList ? this.erjiList[0].childList[0].id : this.erjiList[0].id);
                            this.activeName = this.activeName || (this.erjiList[0] ? this.erjiList[0].id : this.erjiList.id);
                            if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
                                this.getProductListByCat();
                            } else {
                                this.getCraftListByCat()
                            }

                        }
                    }
                }
            });
        },
        //同过工艺——产品分类来查询 产品
        getCraftListByCat() {
            this.$http.get(this.$API.getCraftListByCat, {params: {'catId': this.checkCatId, 'pageNo': this.pageNo, 'pageSize': this.pageSize}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.noProduct = false
                        this.productList.push(...res.data.result.records)
                        if (res.data.result.pages === this.pageNo) {
                            this.showViewMore = false
                            return;
                        }
                        this.showViewMore = true
                        this.pageNo++;
                    } else {
                        this.showViewMore = false
                        this.noProduct = true
                    }

                }
            });
        },
        LoadMore() {
            if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
                this.getProductListByCat()
            } else {
                this.getCraftListByCat()
            }
        }
    }
}
</script>

<style scoped lang="less">
@color: #51b953;
.protype {
    margin-bottom: 20px;

    .select {
        .info {
            font-size: 24px;
            color: #1e1e1e;
            font-weight: bold;
        }


        .erji {
            margin: 20px 0;

            .title {
                background: #344e6c;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
                position: relative;
                width: 100%;
                margin: 0 !important;
                text-align: left;

                &.active {
                    background: @color;
                }

                .icon {
                    position: absolute;
                    left: 15px;
                    font-size: 16px;

                    .jian {
                        display: none;
                    }
                }

            }

            .is-active {
                .title {
                    .jia {
                        display: none;
                    }

                    .jian {
                        display: block;
                    }
                }
            }

            .sanji {
                margin-top: 10px;
                background: #7b8c9f;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;

                &.active {
                    background: @color;
                }
            }
        }
    }

    .Brochures {
        background: #88d24e;
        padding: 38px 20px 25px;

        .tit {
            font-size: 24px;
            color: #fff;
        }

        .con {
            font-size: 16px;
            color: #fff;
            line-height: 1.6;
            margin: 25px 0;
        }

        .btn {
            background: #ffffff;
            color: #737373;
            line-height: 60px;
            cursor: pointer;
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.product01 {
    .title {
        margin: 60px 0 30px;
        text-align: center;

        .tit {
            font-size: 40px;
            color: #1e1e1e;
            margin-bottom: 20px;
        }

        .con {
            font-size: 16px;
            color: #595959;
            padding: 0 6.5%;
        }
    }

    .content {
        .left {
            width: 73.5%;
            margin: 40px 0;

            .item {
                width: 33.3%;
                float: left;
                cursor: pointer;
                box-sizing: border-box;
                padding-right: 25px;
                margin-bottom: 30px;

                .img {
                    img {
                        width: 100%;
                    }
                }

                .tit {
                    text-align: center;
                    font-size: 18px;
                    color: #1e1e1e;
                    padding: 15px 0;
                }
            }

            .load {
                text-align: center;

                .view_more {
                    display: inline-block;
                    font-size: 18px;
                    font-weight: bold;
                    color: #1d1d1d;
                    padding: 15px 90px;
                    border: 1px solid #dcdcdc;
                    cursor: pointer;
                    transition: .5s ease;

                    &:hover {
                        background: @color;
                        color: #fff;
                    }
                }
            }
        }

        .right {
            width: 26.5%;

            .select {
                .info {
                    font-size: 24px;
                    color: #1e1e1e;
                    font-weight: bold;
                }

                .erji {
                    margin: 20px 0;
                }
            }

        }
    }
}

@media only screen and (max-width: 1240px) {
    .product01 .title .tit {
        font-size: 35px;
    }

}

@media only screen and (max-width: 992px) {
    .product01 .content .left .item {
        width: 50%;
    }
}

@media only screen and (max-width: 760px) {
    .product01 {
        .title {
            margin: 20px 0;

            .tit {
                font-size: 20px;
            }

            .con {
                font-size: 14px;
                padding: 0;
            }
        }

        .content {
            .right {
                width: 100%;

            }

            .left {
                width: 100%;
                margin: 0;

                .item {
                    padding: 0 5px;
                    margin-bottom: 5px;

                    .tit {
                        font-size: 14px;
                    }
                }

                .load {
                    .view_more {
                        padding: 10px 30px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .protype {
        .select {
            .info {
                font-size: 20px;
            }

            .erji .title {
                padding: 10px 20px;
                font-size: 15px;
            }
        }

        .Brochures {
            display: none;
            padding: 20px 10px;

            .tit {
                font-size: 18px;
            }

            .con {
                font-size: 14px;
                margin: 15px 0;
            }

            .btn {
                font-size: 14px;
                line-height: 45px;
            }
        }
    }
}
</style>
<style lang="less">
@color: #51b953;
.protype .select .erji {
    .el-collapse {
        border: none;
    }

    .el-collapse-item {
        margin-bottom: 20px;

        .active.title {
            background: @color;
        }
    }

    .el-collapse-item__header {
        height: auto;
        line-height: inherit;
    }

    .el-collapse-item__arrow {
        display: none;
    }

    .el-collapse-item__content {
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 760px) {
    .protype .select .erji .el-collapse-item {
        margin-bottom: 10px;
    }
}
</style>
