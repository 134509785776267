<template>
    <div class="toContact">
        <div class="wrap fix">
            <div class="img l">
                <img src="../static/image/index/ipart01_1.png" alt="">
            </div>
            <div class="text l">
                Do You Have A Metal Part
                We Can Help With?
            </div>
            <div @click="toContact" class="btn l">GET A FREE QUOTE</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ToContact",
    methods:{
        toContact() {
            this.$router.push({name: 'Contact Us', query: {toAnchor: 'contact_form', id: '1483421481972543490'}, params: {toAnchor: 'contact_form'}})
        },
    }
}
</script>

<style scoped lang="less">

@color: #51b953;
.background {
    background-image: @color;
    background-image: linear-gradient(to right, #6ab62c, #329e4c);
}

.toContact {
    background: url('../static/image/product/product04.jpg') no-repeat center;
    background-size: cover;
    padding: 50px 0;

    .text {
        font-size: 34px;
        color: #fff;
        width: 40%;
        margin: 0 15% 0 5%;
        line-height: 1.2;
    }

    .btn {
        width: 25.4%;
        height: 68px;
        line-height: 68px;
        color: #ffffff;
        font-size: 25px;
        text-align: center;
        border-radius: 50px;
        cursor: pointer;
        .background();
    }
}

@media only screen and (max-width: 1366px) {
    .toContact {
        .text {
            font-size: 35px;
            width: 45%;
            margin: 0 10% 0 5%;
        }

        .btn {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .toContact {
        .text {
            font-size: 25px;
            margin: 0 3%;

        }

        .btn {
            width: 33%;
            font-size: 17px;
        }

    }
}

@media only screen and (max-width: 760px) {
    .toContact {
        padding: 20px 0;
        .img{
            display: none;
        }
        .text {
            font-size: 18px;
            margin:0 0 10px;
            width: 100%
        }

        .btn {
            width: 56%;
            margin-left: 22%;
            height: 46px;
            line-height: 46px;
            font-size: 14px;
        }
    }
}
</style>
