<template>
    <div class="contact">
        <xc-banner :banner-id="id" name="Contact Us"/>
        <div class="contact01">
            <div class="wrap">
                <div class="title">
                    <div class="tit">CONTACT US</div>
                    <div class="con">You Can Find Us Here</div>
                </div>
                <div class="content fix">
                    <div class="item l wow fadeInUp" data-wow-duration="1s">
                        <div class="left l">
                            <i class="iconfont icon-dianhua"></i>
                        </div>
                        <div class="right l">
                            <div class="tit">Phone:</div>
                            <div class="con">
                                <a :href="'tel:'+ $SYS.COMMON_TEL">
                                    {{$SYS.COMMON_TEL}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="item l wow fadeInUp" data-wow-duration="1.2s">
                        <div class="left l">
                            <i class="iconfont icon-dizhi"></i>
                        </div>
                        <div class="right l">
                            <div class="tit">Address:</div>
                            <div class="con">
                                {{$SYS.COMMON_ADDRESS}}
                            </div>
                        </div>
                    </div>
                    <div class="item l wow fadeInUp" data-wow-duration="1.4s">
                        <div class="left l">
                            <i class="iconfont icon-duanxin"></i>
                        </div>
                        <div class="right l">
                            <div class="tit">E-mail:</div>
                            <div class="con">
                                <a :href="'mailto:'+$SYS.COMMON_EMAIL">
                                    {{$SYS.COMMON_EMAIL}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map_wrapper">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51539.46543368527!2d120.07050633085537!3d36.16128373857375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3595e341e72634a1%3A0x243d517973f12e55!2z5Lit5Zu95bGx5Lic55yB6Z2S5bKb5biC6IO25bee5biC5bCa5b635aSn6YGTICYg6Ze95rGf6LevIOmCruaUv-e8lueggTogMjY2NDI2!5e0!3m2!1szh-CN!2shk!4v1648452543329!5m2!1szh-CN!2shk" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <!--<Mapbaidu :map="map"/>-->
                </div>
            </div>
        </div>
        <div class="contact02" id="contact_form">
            <div class="wrap">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline-message="inlineMessage" class="demo-ruleForm">
                    <div class="form fix">
                        <div class="item l">
                            <el-form-item prop="name">
                                <el-input v-model="ruleForm.name" placeholder="Your Name"></el-input>
                            </el-form-item>
                            <el-form-item prop="email">
                                <el-input v-model="ruleForm.email" placeholder="Your Email"></el-input>
                            </el-form-item>
                            <el-form-item prop="country">
                                <el-input v-model="ruleForm.country" placeholder="Your Country"></el-input>
                            </el-form-item>
                            <el-form-item prop="subject">
                                <el-input v-model="ruleForm.subject" placeholder="Your Subject"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item r">
                            <el-form-item prop="subject">
                                <el-input type="textarea" v-model="ruleForm.message" placeholder="Your Message"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="form_btn fix">
                        <div class="item l">
                            <!--<div class="text">We will contact you within one business day.</div>-->
                        </div>
                        <div class="item r">
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')">SEND MESSAGE</el-button>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import xcBanner from "/src/components/xcBanner";
import Mapbaidu from "/src/components/Mapbaidu";
import $ from 'jquery'
import WOW from 'wowjs'
export default {
    name: "contact",
    components: {
        Mapbaidu,
        xcBanner
    },
    data() {
        let checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error('The mailbox can\'t be empty'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('Please enter the correct email format'))
                }
            }, 100)
        }
        return {
            name:'Contact Us',
            id:this.$route.query.id,
            map: {
                center: {lng: 24.96676, lat: 45.94316},
                zoom: 7,
                show: true,
                dragging: true,
                title: 'Address:Breaza,Grivitei str.,nr.18,Prahova county.100KM to Burcherast'
            },
            ruleForm: {
                name: '',
                email: '',
                country: '',
                subject: '',
                message: ''
            },
            inlineMessage: false,
            rules: {
                name: [
                    {required: true, message: 'Please enter your name', trigger: 'blur'},
                    {max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: 'Please enter your email', trigger: 'blur'},
                    {validator: checkEmail, trigger: 'blur'}

                ],
                country: [
                    {required: true, message: 'Please enter your country', trigger: 'blur'},
                    { max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                object: [
                    {required: false, message: 'Please enter your object', trigger: 'blur'},
                ],
                message: [
                    {required: false, message: 'Please enter message', trigger: 'blur'}
                ]
            }
        }
    },
    created() {
        new WOW.WOW().init()
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
    },
    mounted() {
        if (this.$route.params.toAnchor) {
            let anchor = document.getElementById(this.$route.params.toAnchor) // 参数为要跳转到的元素id
            let scrollTop = anchor.offsetTop - document.querySelector('.headerContent').clientHeight -10
            $('body,html').animate({scrollTop:scrollTop},500)
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http.post(this.$API.postForm,{name:this.ruleForm.name,email:this.ruleForm.email,country:this.ruleForm.country,subject:this.ruleForm.subject,message:this.ruleForm.message}).then((res) => {
                        if(res.data.code === 200){
                            this.$message.success({
                                message:res.data.result,
                                offset:100
                            });
                        }
                    })
                } else {
                    this.$message.error({
                        message:'error submit',
                        offset:100
                    });
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped lang="less">
@color: #6fba2a;
.contact01 {
    .title {
        padding: 50px 0 60px;

        .tit {
            color: @color;
            font-size: 18px;
            margin-bottom: 15px;
        }

        .con {
            font-size: 33px;
            color: #1f1f1f;

        }
    }

    .content {
        margin-bottom: 35px;

        .item {
            float: left;
            width: 33.3%;

            .left {
                background: @color;
                width: 95px;
                height: 95px;
                color: #ffffff;
                border-radius: 50%;
                text-align: center;

                .iconfont {
                    font-size: 45px;
                    line-height: 95px;
                }

            }

            .right {
                width: 61%;
                padding-left: 20px;

                .tit {
                    font-size: 22px;
                    color: #000000;
                    font-weight: bold;
                    margin: 20px 0 10px;
                }

                .con {
                    font-size: 18px;
                    color: #212121;
                    line-height: 1.4;

                    a {
                        color: #212121;

                        &:hover {
                            color: @color;
                        }
                    }
                }
            }
        }
    }

    .map {
        width: 100%;
        height: 375px;

    }
}

.contact02 {
    margin: 50px 0;

    .form {
        height: 270px;
    }

    .item {
        width: 48%;
    }
}
@media only screen and (max-width: 992px) {
    .contact01 .content .item .right{
        width:100%;
    }

}

@media only screen and (max-width: 760px) {
    .contact01  {
        .title{
            padding:20px 0;
            .tit{
                font-size: 16px;
            }
            .con{
                font-size: 20px;
            }
        }
        .content{
            .item{
                .left{
                    width:50px;
                    height: 50px;
                    .iconfont{
                        line-height: 50px;
                        font-size: 20px;
                    }

                }
                .right{
                    width:75%;
                    padding-left: 20px;
                    margin-top: 9px;
                    .tit{
                        display: inline;
                        font-size: 18px;
                        margin-top: 0;
                    }
                    .con{
                        display:inline;
                        font-size: 16px;
                    }
                }
                width:100%;
                margin-bottom: 20px;
            }
        }
    }
    .contact02{
        margin:20px 0;
        .item{
            width:100%;
            .text{
                margin:5px 0;
            }
        }
    }
}
</style>
<style lang="less">
@color: #6fba2a;
.contact02 {
    .el-form-item {
        margin-bottom: 10px;
    }

    .el-input__inner, .el-textarea__inner {
        background: #f6f6f6;
        border: 1px solid #e3e2e7;
        line-height: 48px;
        height: 48px;
    }

    .el-textarea__inner {
        height: 220px;
    }

    .el-button--primary {
        background: #6fba2a;
        float: right;
        line-height: 50px;
        height: 50px;
        padding: 0 30px;
        transition: .5s ease;
        border: none;
        border-radius: 0;

        &:focus, &:hover {
            background: #3ca347;
        }
    }
    .el-form-item__error{
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        left: unset;
    }
}

@media only screen and (max-width: 760px) {
    .contact02{
        .el-button--primary{
            float:none;
            margin:0 auto;
            display: block;
        }
    }

}
</style>
