import axios from "axios";
import { Message } from "element-ui";
import qs from 'qs';
import store from '@/store';
import {baseURL, SysConfig} from "../common/constants";


// 创建axios实例
const service = axios.create({
    // api的base_url
    // baseURL: process.env.NODE_ENV === "dev" ? "/api" : process.env.VUE_APP_URL, // 本地-前端解决跨域
    baseURL: baseURL, // 本地-后端解决跨域后
    timeout: 15000 // 请求超时时间
});
// 请求格式 json（默认）
service.defaults.headers.put['Content-Type'] = SysConfig.REQ_CONTENT_TYPE.JSON;
// request 请求拦截器
service.interceptors.request.use(
    config => {
        config.data = {
            sysOrgCode: SysConfig.SYS_ORG_CODE,
            ...config.data
        }
        config.params = {
            sysOrgCode: SysConfig.SYS_ORG_CODE,
            ...config.params
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// respone 响应拦截器
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 400) {
            Message({
                message: "参数信息有误",
                center: true
            });
            return;
        } else if (error.response.status === 302) {
            Message({
                message: "用户未登录",
                center: true
            });
            return;
        } else if (error.response.status === 404) {
            Message({
                message: "连接失败",
                center: true
            });
            return;
        } else if (error.response.status === 500) {
            Message({
                message: "服务器内部错误",
                center: true
            });
            return;
        } else if (error.response.status === 560) {
            Message({
                message: "数据库异常",
                center: true
            });
            return;
        }
        Message({
            message: error.message,
            type: "error",
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;
