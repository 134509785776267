import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {SysConfig, API} from '/src/common/constants'
import util from "/src/common/util";
import axios from "axios";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'babel-polyfill'
import 'animate.css'
import BaiduMap from 'vue-baidu-map'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VideoPlayer from 'vue-video-player'
import service from "./api/API";
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

Vue.use(Antd)
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak: ''    //这个地方是官方提供的ak密钥
})
Vue.use(VideoPlayer)
Vue.config.productionTip = false
Vue.prototype.$SYS = SysConfig
Vue.prototype.$API = API
Vue.prototype.$store = store
Vue.prototype.$axios = axios
Vue.prototype.$util = util
Vue.prototype.$http = service

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
