<template>
    <baidu-map class="map" :center="map.center" :zoom="map.zoom" @ready="handler">
        <!--缩放-->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
        <!--定位-->
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <!--点-->
        <bm-marker :position="map.center" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP">
            <!--提示信息-->
            <bm-info-window :show="map.show">{{ map.title }}</bm-info-window>
        </bm-marker>
    </baidu-map>
</template>

<script>
export default {
    name: "Mapbaidu",
    props: {
        map: {
            type: Object,
            default() {
                return {
                    center: {lng: 24.96676, lat: 45.94316},
                    zoom: 7,
                    show: true,
                    dragging: true,
                    title: 'Address:Breaza,Grivitei str.,nr.18,Prahova county.100KM to Burcherast'
                };
            },
        },
    },
    methods: {
        handler({BMap, map}) {
            let me = this;
            // console.log(BMap, map)
            // 鼠标缩放
            map.enableScrollWheelZoom(true);
            // 点击事件获取经纬度
            map.addEventListener('click', function (e) {
                // console.log(e.point.lng, e.point.lat)
            })
        }
    }
}
</script>

<style lang="less">
.contact01{
    .map{
        img{
            overflow:hidden!important;
            max-width:none!important;
        }
    }
}
</style>
