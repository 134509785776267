
export default {
    namespaced: true,
    state: {
        checkedTab: 'home'
    },
    mutations: {
        setCheckedTab(state, name) {
            state.checkedTab = name
        }
    },
    actions: {
    }
}
