<template>
    <div class="header">
        <div class="headerTop">
            <div class="wrap fix">
                <div class="left">
                    <a :href="$SYS.LINK_FACEBOOK"><i class="iconfont icon-facebook"></i></a>
                    <a :href="$SYS.LINK_TTWW"><i class="iconfont icon-ttww"></i></a>
                    <a :href="$SYS.LINK_LINKIN"><i class="iconfont icon-linkedin"></i></a>
                    <a :href="$SYS.LINK_INSTAGRAM"><i class="iconfont icon-instagram"></i></a>
                </div>
                <div class="right">
                    <a :href="'tel:'+ $SYS.COMMON_TEL"><i class="iconfont icon-dianhua2"></i>{{ $SYS.COMMON_TEL }}</a>
                    <a :href="'mailto:'+$SYS.COMMON_EMAIL"><i class="iconfont icon-youxiang"></i>{{ $SYS.COMMON_EMAIL }}</a>
                    <div class="headerLang" @mouseenter="toggleLang" @mouseleave="toggleLang">
                        <div class="lang_tit"><span
                            class="iconfont icon-control-arr-copy-copy"></span><span class="text">Language</span></div>
                        <div class="lang_down">
                            <div class="sub_lang" v-for="(item,i) in langList" :key="i">
                                <a :href="item.url" target="_blank">{{ item.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="headerBottom">
            <div class="headerContent">
                <div class="wrap fix">
                    <router-link to="/">
                        <h1 class="logo"><img alt="logo" title="logo" src="../assets/logo.png"></h1>
                    </router-link>
                    <div class="mobile_key" @click="showMobileNav"><span></span></div>
                    <div class="mobile_show mobile_lang r">
                        <div class="headerLang" @click="toggleLang">
                            <div class="lang_tit"><span
                                class="iconfont icon-control-arr-copy-copy"></span><span class="text">Language</span> </div>
                            <div class="lang_down">
                                <div class="sub_lang" v-for="(item,i) in langList" :key="i">
                                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="headerSearchBtn r" @click="showSearch = !showSearch"><i
                        class="iconfont icon-sousuo2"></i></div>
                    <nav class="r">
                        <ul class="fix">
                            <li class="nav_li" v-for="(item, index) in navList" :key="index" @mouseenter="mouseAction(item)"
                                @mouseleave="leaveAction(item)" @click="mobileNav(item)"
                                :class="[ item.hasChild === '1' ? '' : 'links']">
                                <div class="title" @click="navLink(item, item.hasChild === '1')"
                                     :class="[$route.path !== '/' && item.name.toLowerCase().indexOf($route.path.split('/')[1]) > -1 ? 'active' : '']">
                                    {{ item.name }}
                                    <i v-if="item.hasChild === '1'" :class="[item.id === checkedNav ? 'on' :'']"
                                       class="iconfont icon-arrowRight-copy"></i>
                                </div>
                                <el-collapse-transition>
                                    <div class="sub_ul" v-show="item.id === checkedNav" v-if="item.hasChild === '1'">
                                        <ul>
                                            <li v-for="(child,i) in item.childList" :key="i" @mouseenter="mouseThird(child)" @mouseleave="leaveThird(child)">
                                                <div @click="navLink(child)">{{ child.name }}</div>
                                                <div class="third_ul" v-show="child.id === checkedThirdNav" v-if="child.hasChild === '1'">
                                                    <div v-for="(thirdLi,i) in child.childList" :key="i" @click="ThirdLink(thirdLi)">{{ thirdLi.name }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-transition>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <!--  搜索框显示  -->
        <transition name="fade">
            <div class="search-alert" v-show="showSearch">
                <div class="cent-form wrap">
                    <span class="close" @click="showSearch = !showSearch"><i class="iconfont icon-cuowu"></i> </span>
                    <i class="iconfont icon-sousuo2"></i>
                    <input class="tex" type="text" v-model="searchInput" placeholder="Keywords" maxlength="50">
                    <span class="sub-butn gotham-bold" @click="searchSubmit">Submit</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'Header',
    data() {
        return {
            searchInput: '',
            showSearch: false,
            navList: [],
            checkedNav: '',
            checkedThirdNav: '',
            langList: [
                {
                    name:'中文',
                    url:'http://cn.huidatrading.com/'
                },
                {
                    name:'   عربي',
                    url:'http://ar.huidatrading.com/'
                },
                {
                    name:'فارس',
                    url:'http://fa.huidatrading.com/'
                }
            ]
        }
    },
    created() {
        this.$http.get(this.$API.getChannelList).then((res) => {
            if (res.data.code === 200) {
                this.navList = res.data.result
                for (let item of this.navList) {
                    item.showUl = false
                }
                let tdk = res.data.result[0];//获取json结果集
                let title = tdk.seoTitle;
                let keywords = tdk.seoKeywords;
                let description = tdk.seoDesc;
                document.title = title //动态添加
                let meta = document.getElementsByTagName('meta');
                meta["keywords"].setAttribute('content', keywords);
                meta["description"].setAttribute('content', description);
            }
        })

        let baseType = this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC
        this.$nextTick(function () {
            $(window).scroll(function () {
                if (baseType) {
                    if ($(window).scrollTop() > 42) {
                        $('.header').addClass('fixed')
                    } else {
                        $('.header').removeClass('fixed')
                    }
                }
            })
        })
    },
    methods: {
        // 语言下拉
        toggleLang() {
            $('.lang_down').stop().slideToggle()
        },
        //头部搜索
        searchSubmit() {
            //console.log(this.searchInput);
        },
        //导航链接
        navLink(item, hasChild) {
            if (!hasChild) {
                let idArr = item.url.split("#");
                let query = {id: item.id, name: item.name}
                if (idArr.length > 1) {
                    query.catId = idArr[1]
                }
                this.$router.push({path: idArr[0], query: query});
                if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_MOBILE) {
                    this.showMobileNav()
                }
            }
        },
        //导航链接
        ThirdLink(item, hasChild) {
            if (!hasChild) {
                let idArr = item.url.split("#");
                let query = {id: item.id, name: item.name, pid: '1484434282423377922'}
                if (idArr.length > 1) {
                    query.catId = idArr[1]
                }
                this.$router.push({path: idArr[0], query: query});
                if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_MOBILE) {
                    this.showMobileNav()
                }
            }
        },
        //导航下拉触控
        mouseAction(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC) {
                this.checkedNav = item.id
            }
        },
        leaveAction(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC) {
                this.checkedNav = ''
            }
        },
        mouseThird(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC) {
                this.checkedThirdNav = item.id
            }
        },
        leaveThird(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC) {
                this.checkedThirdNav = ''
            }
        },
        //移动端导航点击
        mobileNav(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_MOBILE) {
                if (this.checkedNav === item.id) {
                    this.checkedNav = ''
                } else {
                    this.checkedNav = item.id
                }
            }
        },
        //移动端导航点击
        mobileThirdNav(item) {
            if (this.$util.deviceType() === this.$SYS.DEVICE_TYPE_MOBILE) {
                if (this.checkedThirdNav === item.id) {
                    this.checkedThirdNav = ''
                } else {
                    this.checkedThirdNav = item.id
                }
            }
        },
        showMobileNav() {
            $('nav').stop().slideToggle(300);
            $('.mobile_key').toggleClass('active')
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@color: #51b953;
.header {
    &.fixed {
        .headerBottom .headerContent {
            position: fixed;
        }
    }

    .mobile_lang {
        line-height: 55px;

        .headerLang {
            .lang_tit {
                font-size: 14px;
            }

            .lang_down {
                top: 100%;

                .sub_lang a {
                    font-size: 14px;
                }
            }

        }
    }

    .headerLang {
        position: relative;
        transition: .5s ease;
        color: #fff;
        display: inline-block;

        span {
            vertical-align: middle;
            margin-right: 2px;
        }

        .lang_tit {
            font-size: 14px;
            cursor: pointer;
        }

        .lang_down {
            position: absolute;
            width: 100%;
            left: 0;
            top: 125%;
            background: rgba(255, 255, 255, 0.9);
            display: none;
            color: #333;
            z-index: 1000;

            .sub_lang {
                a {
                    line-height: 30px;
                    text-align: center;
                    color: #333;
                    display: block;

                    &:hover {
                        color: @color;
                    }
                }
            }
        }
    }
}

.headerTop {
    background: @color;

    .left {
        float: left;
    }

    .right {
        float: right;

        .iconfont {
            margin-right: 5px;
        }
    }

    a {
        color: #fff;
        line-height: 42px;
        margin: 0 5px;

        &:hover {
            opacity: 0.8;
        }
    }
}

.headerBottom {
    position: relative;
    z-index: 100;

    .headerContent {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;

        a {
            display: inline-block;
            font-size: 0;
        }

        .logo {
            padding: 10px 0;
            display: inline-block;
        }

        .headerSearchBtn {
            display: none;
            margin-top: 27px;
            cursor: pointer;

            .iconfont {
                color: #fff;
                font-size: 20px;

                &:hover {
                    color: @color;
                }
            }
        }
    }
}

/* 搜索下拉 */
.search-alert {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 117px;
    background: @color;
    z-index: 101;
    transition: all 0.5s ease;

    .iconfont {
        color: #fff;
        font-size: 25px;
    }

    .cent-form {
        position: absolute;
        width: 66%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 65px;
        line-height: 65px;
        border-bottom: 1px solid #fff;

        .close {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: -2.6%;
            cursor: pointer;
            transition: .5s ease;

            &:hover {
                transform: rotateZ(180deg);
            }
        }

        input.tex {
            border: none;
            background: transparent;
            font-size: 20px;
            height: 65px;
            list-style: none;
            text-align: left;
            width: 86%;
            box-sizing: border-box;
            color: #fff;
            padding-left: 20px;

            &::-webkit-input-placeholder {
                color: #fff;
            }

            &:-ms-input-placeholder {
                color: #fff;
            }

            &:-ms-input-placeholder {
                color: #fff;
            }

            &::-moz-placeholder {
                color: #fff;
            }
        }

        .sub-butn {
            border: none;
            width: 120px;
            background: none;
            position: absolute;
            right: 0;
            top: 0;
            text-align: center;
            height: 65px;
            font-size: 22px;
            line-height: 65px;
            color: #fff;
            cursor: pointer;
        }

    }
}

/*导航*/
nav {
    margin-right: 20px;

    ul {
        margin-bottom: 0;

        li.nav_li {
            position: relative;
            color: #fff;
            float: left;
            line-height: 75px;
            margin: 0 25px;
            cursor: pointer;
            font-size: 16px;

            .title.active {
                color: @color;
            }

            .iconfont {
                font-size: 14px;
                margin-left: 12px;
                transition: .5s ease;

                &.on {
                    transform: rotateZ(180deg);
                }
            }

            &.links {
                transition: .5s ease;
            }

            &.links:hover {
                color: @color;
            }

            .sub_ul {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.6);
                min-width: 195px;
                top: 100%;

                ul {
                    padding: 50px 0;

                    li {
                        line-height: 40px;
                        font-size: 14px;
                        transition: .5s ease;
                        padding: 0 20px;
                        position: relative;

                        .third_ul {
                            position: absolute;
                            background-color: rgba(0, 0, 0, 0.6);
                            min-width: 195px;
                            left: 100%;
                            top: 0;
                            padding: 20px;

                            div {
                                cursor: pointer;
                                transition: .5s ease;

                                &:hover {
                                    color: @color;
                                }
                            }
                        }

                        &:hover {
                            color: @color;

                            .third_ul {
                                div {
                                    color: #fff;

                                    &:hover {
                                        color: @color;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    top: -100%;
}

.mobile_key {
    display: none;
}

@media only screen and (max-width: 992px) {
    .mobile_key {
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 10;
        display: block;
        width: 20px;
        height: 70px;
        float: right;
        margin-left: 10px;
    }

    .mobile_key span,
    .mobile_key:before,
    .mobile_key:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 2px;
        margin-left: -10px;
        background: #fff;
        border-radius: 2px;
        display: inline-block;
        transition: all 0.3s ease-in-out;
    }

    .mobile_key span {
        margin-top: -4px;
    }

    .mobile_key:before {
        content: '';
        margin-top: 3px;
    }

    .mobile_key:after {
        content: '';
        margin-top: 10px;
    }

    .active.mobile_key:before {
        opacity: 0;
        transition: 0.2s ease 0.1s;
    }

    .active.mobile_key:after {
        margin-top: 2px !important;
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, -webkit-transform 0.25s ease 0.36s;
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, transform 0.25s ease 0.36s;
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, transform 0.25s ease 0.36s, -webkit-transform 0.25s ease 0.36s;
    }

    .active.mobile_key span {
        margin-top: 2px !important;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, -webkit-transform 0.25s ease 0.36s;
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, transform 0.25s ease 0.36s;
        transition: margin 0.2s cubic-bezier(0.36, 0, 0.59, 0.99) 0.1s, transform 0.25s ease 0.36s, -webkit-transform 0.25s ease 0.36s;
    }

    nav {
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: #484646;
        padding: 10px 0;
        display: none;

        ul li.nav_li {
            width: 100%;
            margin: 0;
            padding: 0 10px;
            line-height: 40px;
            box-sizing: border-box;

            &:last-child {
                .title {
                    border: none;
                }
            }

            .iconfont {
                float: right;
            }

            .sub_ul {
                position: static;
                width: 100%;
                background: transparent;

                ul {
                    padding: 10px 20px;

                    li {
                        padding: 0;

                        .third_ul {
                            display: block !important;
                            position: static;
                            background-color: transparent;
                            padding: 0 15px;
                        }
                    }
                }

            }
        }
    }

    .search-alert .cent-form {
        width: 90%;
    }

}

@media only screen and (max-width: 760px) {
    .headerTop {
        display: none;
    }

    .headerBottom .headerContent {
        position: static;
        background-color: #000;

        .logo img {
            height: 40px;
        }

        .headerSearchBtn {
            margin-top: 17px;
        }
    }

    .mobile_key {
        height: 55px;
    }

    nav ul li.nav_li {
        font-size: 14px;

        .title {
            border-bottom: 1px solid #555;
        }
    }

    .search-alert {
        height: 60px;

        .iconfont {
            font-size: 16px;
        }

        .cent-form {
            width: 90%;
            margin-right: 6%;
            height: 40px;
            line-height: 40px;

            input.tex {
                height: 40px;
                font-size: 14px;
            }

            .sub-butn {
                font-size: 14px;
                line-height: 40px;
                height: 40px;
                width: auto;
                right: 20px;
            }
        }
    }
}

</style>
