/**
 * The file enables `@/store/store.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('./modules', false, /\.js$/)
const modules = {}

// 匹配此目录下的文件，并作为modules对象的属性，方便新增Vuex的module自动注册
files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default {
    namespaced: true,
    modules
}
