<template>
    <div class="casting">
        <xc-banner name="Metal Casting" :banner-id="id"/>
        <div class="casting01">
            <div class="wrap fix">
                <div class="left l">
                    <div class="name">{{ content_one.title }}</div>
                    <div class="title">{{ content_one.subTitle }}</div>
                    <div class="text" v-html="content_one.content"></div>
                    <div class="icon fix">
                        <div class="img l">
                            <img :src="content_one.icon" :alt="content_one.iconText">
                        </div>
                        <div class="icon-text l">
                            {{ content_one.iconText }}
                        </div>
                    </div>
                </div>
                <div class="right r imgZoom">
                    <img :src="$SYS.IMG_PREFIX + content_one.img" :alt="content_one.title">
                </div>
            </div>
        </div>
        <div class="casting02">
            <div class="wrap">
                <div class="name">{{ content_two.title }}</div>
                <div class="title">{{ content_two.subTitle }}</div>
                <div class="text">{{ content_two.text }}</div>
                <div class="content">
                    <div class="one fix">
                        <div class="item imgZoom l" v-for="(item,i) in imgList.slice(0,2)" :key="i" @click="toErji(item)">
                            <div class="img">
                                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.enName">
                            </div>
                            <div class="cover">
                                <div class="one_name">{{ item.enName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="two fix">
                        <div class="item imgZoom l" v-for="(item,i) in imgList.slice(2,5)" :key="i" @click="toErji(item)">
                            <div class="img">
                                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.enName">
                            </div>
                            <div class="cover">
                                <div class="one_name">{{ item.enName }}</div>
                            </div>
                        </div>
                        <div class="item l">
                            <div class="box imgZoom" v-for="(item,i) in imgList.slice(5,7)" :key="i" @click="toErji(item)">
                                <div class="img">
                                    <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.enName">
                                </div>
                                <div class="cover">
                                    <div class="one_name">{{ item.enName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="casting03">
            <div class="wrap fix">
                <div class="tab l">
                    <div v-for="(item,i) in erjiList" :key="i" class="title" :class="[item.id === checkCatId ? 'active' : '']" @click="changePro(item)">{{ item.enName }}</div>
                </div>
                <div class="tab_t r">
                    <div class="title">{{ proList.name }}</div>
                    <div class="content fix">
                        <div class="item l" v-for="(item,i) in proList.product" :key="i" @click="toDetail(item)">
                            <div class="img imgZoom rect-100">
                                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                            </div>
                        </div>
                    </div>
                    <div class="noProduct" v-if="noProduct">
                        <a-empty />
                    </div>
                    <div class="view_more" @click="toProduct" v-if="!noProduct"><span>VIEW MORE</span></div>
                </div>
            </div>
        </div>
        <div class="caseing04">
            <CrastText v-for="(item, i) in content_three" :key="i" :item="item"/>
        </div>
        <div class="casting05">
            <div class="wrap">
                <div class="title">
                    <div class="tit">{{ partner.title }}</div>
                    <div class="con">{{ partner.text }}</div>
                </div>
                <div class="content">
                    <partner/>
                </div>
            </div>
        </div>
        <div class="casting06">
            <to-contact/>
        </div>
    </div>
</template>

<script>
import XcBanner from "../../components/xcBanner";
import CrastText from "/src/components/CrastText";
import Partner from "/src/components/Partner";
import ToContact from "/src/components/ToContact";

export default {
    name: "casting",
    components: {ToContact, Partner, CrastText, XcBanner},
    data() {
        return {
            catId: this.$route.query.catId,
            id: this.$route.query.id,
            checkCatId: this.$route.query.childCatId,
            name:'',
            channelId:'',
            craftList:[],
            content_one: {},
            noProduct: false,
            content_two: {
                name: '制造工艺',
                title: 'Processes available to manufacture metal casting products',
                text: 'To meet the diverse requirements of the metal casting products, the processes we can apply to the production include',
            },
            erjiList:[],
            imgList: [],
            proList: {
                name: 'Zhangshi offers sand casting production and custom metal casting parts.',
                product: []
            },
            content_three: [],
            partner: {
                title: 'Our Partners',
                text: 'With our specialty in metal parts production, we have been serving many customers engaged in different industries like Home Appliances, Construction, Automobiles, Heavy Equipment, etc.'
            }
        }
    },
    created() {
        this.getCraftCatList(true)
        this.$http.get(`${this.$API.getPageByChannelId}?channelId=${this.id}`).then(res => {
            if (res.data.code === 200) {
                if (res.data.result.length > 0) {
                    this.content_one = res.data.result[0]
                    this.content_one.icon = require('../../static/image/casting/casting02.jpg')
                    this.content_one.iconText = 'Zhangshi company can meet our customers’ fabricating requirements for a wide variety of one-stop metal products, saving time and money for both of us.'
                    this.content_three = res.data.result.slice(1,3)
                    this.content_three[0].icon = require('../../static/image/casting/casting_icon1.png')
                    this.content_three[1].icon = require('../../static/image/casting/casting_icon2.png')
                }
            }
        });
    },
    methods: {
        //获取工艺分类接口
        getCraftCatList(isFirst) {
            this.$http.get(`${this.$API.getCraftCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    if(res.data.result.length > 0){
                        this.imgList = res.data.result[0].childList
                        //获取工艺栏目下层接口
                        this.$http.get(this.$API.getChannelList).then((res) => {
                            if(res.data.code === 200){
                                this.craftList = res.data.result[1].childList[0].childList
                                for(let item of this.imgList){
                                    for(let channel of this.craftList){
                                        if(item.id === channel.url.split('#')[1]){
                                            item.channelId = channel.id
                                            break;
                                        }
                                    }
                                }
                                this.erjiList = this.imgList
                                if(isFirst){
                                    this.checkCatId = this.erjiList[0].id;
                                    this.channelId = this.erjiList[0].channelId;
                                    this.name = this.erjiList[0].enName
                                    document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
                                    this.getCraftListByCat()
                                }
                            }
                        })

                    }
                }
            });
        },
        //点击工艺进入工艺详情页面
        toErji(item){
            this.$router.push({path: '/capabilities/processdetail',query:{catId:item.id,id:item.channelId,name:item.enName,pid:this.catId}})
            // this.$router.push({path:'/capabilities/processdetail',query:{id:item.id}})
        },
        //点击产品分类查询对应的产品列表
        changePro(item) {
            this.checkCatId = item.id
            this.channelId = item.channelId
            this.name = item.enName
            document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
            this.getCraftListByCat()
        },
        //同过工艺——产品分类来查询 产品
        getCraftListByCat() {
            this.$http.get(this.$API.getCraftListByCat, {params: {'catId': this.checkCatId}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.noProduct = false
                        if(res.data.result.records.length > 3){
                            this.proList.product = res.data.result.records.slice(0,3)
                        }else{
                            this.proList.product = res.data.result.records
                        }

                    }else{
                        this.noProduct = true
                        this.proList.product = []
                    }

                }
            });
        },
        //点击进入相应的产品列表
        toProduct(){
            this.$router.push({path:'/capabilities/products', query: {id:this.channelId,catId:this.catId,childCatId:this.checkCatId, name:this.name}});
        },
        //点击产品跳转到产品详细页面
        toDetail(item) {
            this.$router.push({path: `/capabilities/${this.channelId}/${this.catId}/${item.id}/${this.checkCatId}/${item.name}`,})
        },
    }
}
</script>

<style scoped lang="less">
@color: #51b953;
.casting01 {
    padding: 65px 0;

    .left {
        width: 54.1%;

        .name {
            color: @color;
            font-size: 18px;
            margin-bottom: 15px;
        }

        .title {
            font-weight: bold;
            color: #1d1d1d;
            font-size: 25px;
            line-height: 1.2;
        }

        .text {
            font-size: 15px;
            color: #5a5a5a;
            margin: 25px 0 35px;
        }

        .icon {
            .img {
                width: 14%;
            }

            .icon-text {
                font-size: 22px;
                color: #5a5a5a;
                padding-left: 15px;
                width: 86%;
                margin-top: 10px;

            }
        }

    }

    .right {
        width: 41.7%;
    }
}

.casting02 {
    background-color: #eeeeee;
    padding: 50px 0;

    .name {
        color: @color;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .title {
        font-size: 28px;
        font-weight: bold;
        color: #1d1d1d;
    }

    .text {
        font-size: 15px;
        color: #5a5a5a;
        margin: 20px 0;
    }

    .content {
        .one {
            margin-bottom: 40px;

            .item {
                cursor: pointer;
                width: 48.5%;
                position: relative;

                &:last-child {
                    float: right;
                }

                .img {
                    position: relative;
                    padding-bottom: 55%;
                    width: 100%;
                    height: 0;
                    overflow: hidden;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .cover {
                    position: absolute;
                    width: 100%;
                    bottom: 10%;
                    left: 0;
                    text-align: center;
                    font-size: 28px;
                    color: #ffffff;
                    font-weight: bold;
                }

            }
        }

        .two {
            .item {
                cursor: pointer;
                width: 22.7%;
                position: relative;
                margin-right: 3.02%;

                &:last-child {
                    margin-right: 0;
                }

                .img {
                    position: relative;
                    padding-bottom: 118%;
                    width: 100%;
                    height: 0;
                    overflow: hidden;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .cover {
                    position: absolute;
                    width: 100%;
                    bottom: 10%;
                    left: 0;
                    text-align: center;
                    font-size: 28px;
                    color: #ffffff;
                    font-weight: bold;
                }

                .box {
                    &:first-child {
                        margin-bottom: 30px;
                    }

                    .img {
                        padding-bottom: 53.4%;
                    }
                }
            }
        }
    }
}

.casting03 {
    margin: 55px 0;

    .tab {
        width: 26.3%;

        .title {
            background-color: #344e6c;
            margin-bottom: 10px;
            padding: 10px 20px;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
            transition: .5s ease;

            &.active {
                background-color: @color;
            }
        }
    }

    .tab_t {
        width: 70.3%;

        .title {
            font-size: 30px;
            color: #1d1d1d;
            margin-bottom: 40px;
        }

        .content {
            .item {
                width: 31%;
                margin-right: 3.5%;
                cursor: pointer;
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
            margin-bottom:30px;
        }
    }
}

.casting05 {
    .title {
        text-align: center;

        .tit {
            font-size: 30px;
            color: #1d1d1d;
            margin-bottom: 15px;
        }

        .con {
            font-size: 17px;
            color: #5a5a5a;
        }
    }

    .content {
        margin: 40px 0 60px;
    }
}

@media only screen and (max-width: 1440px) {
    .casting02 .content .one .item .cover {
        font-size: 25px;
    }

    .casting02 .content .two .item .cover {
        font-size: 25px;
    }

}

@media only screen and (max-width: 760px) {
    .casting01 {
        padding: 20px 0;

        .left {
            width: 100%;
            margin-bottom: 20px;

            .name {
                font-size: 16px;
            }

            .title {
                font-size: 20px;
            }

            .text {
                font-size: 14px;
                margin: 20px 0;
            }

            .icon {
                .icon-text {
                    font-size: 14px;
                }
            }
        }

        .right {
            width: 100%;
        }
    }

    .casting02 {
        padding: 20px 0;

        .name {
            font-size: 16px;
        }

        .title {
            font-size: 20px;
        }

        .text {
            font-size: 14px;
        }

        .content {
            .one {
                margin-bottom: 0;

                .item {
                    width: 100%;
                    margin-bottom: 10px;

                    .cover {
                        font-size: 16px;
                    }
                }
            }

            .two {
                .item {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 10px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }

                    .cover {
                        font-size: 16px;
                    }

                    .box:first-child {
                        margin-bottom: 10px;
                    }
                    .img{
                        padding-bottom: 55%;
                        img{
                            height:auto;
                        }
                    }
                }
            }
        }
    }

    .casting03 {
        margin: 20px 0 0;
        .view_more{
            margin-bottom: 0;
        }
        .tab {
            width: 100%;

            .title {
                font-size: 16px;
            }
        }

        .tab_t {
            width: 100%;

            .title {
                font-size: 20px;
                margin-bottom: 20px;
            }

            .content {
                margin-bottom: 20px;
                .item {
                    width: 32%;
                    margin-right: 1%;
                }
            }
        }
    }

    .casting05 {
        .title {
            .tit {
                font-size: 20px;
            }

            .con {
                font-size: 14px;
            }
        }

        .content {
            margin: 20px 0;
        }
    }


}
</style>
