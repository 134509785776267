<template>
    <div class="partner fix">
        <div class="img_box" v-for="(item,i) in picList" :key="i">
            <a :href="item.url" target="_blank">
                <div class="imgZoom">
                    <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                </div>
            </a>
        </div>

        <!--<div class="swiper-wrapper">-->
        <!--    <swiper :options="swiperOption">-->
        <!--        <swiper-slide v-for="(item,i) in picList" :key="i">-->
        <!--            <a :href="item.url"  target="_blank">-->
        <!--                <div class="imgZoom">-->
        <!--                    <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">-->
        <!--                </div>-->
        <!--            </a>-->
        <!--        </swiper-slide>-->
        <!--    </swiper>-->
        <!--    &lt;!&ndash;<div class="swiper-pagination" slot="pagination"></div>&ndash;&gt;-->
        <!--</div>-->
    </div>
</template>

<script>
// import 'swiper/dist/css/swiper.css'    //在全局没引入，这里记得要！
// import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
    name: "Partner",
    data() {
        return {
            // swiperOption: {
            //     // 所有的参数同 swiper 官方 api 参数一样
            //     slidesPerView: 'auto',
            //     spaceBetween: 20,
            //     // autoplay:3000,
            // },
            picList: []
        }
    },
    // components: {
    //     swiper,
    //     swiperSlide
    // },
    created() {
        this.$http.get(this.$API.getCoopPartnerList).then((res) => {
            if (res.data.code === 200) {
                this.picList = res.data.result
            }
        })
    }
}
</script>

<style scoped lang="less">
.partner {
    text-align: center;

    .img_box {
        display: inline-block;
        margin: 0 0.5%;
        width: 12.5%;
    }
}

.partner .swiper-container {
    width: 100%;
}

.partner .swiper-slide {
    width: auto;
}

@media only screen and (max-width: 992px) {
    .partner{
        text-align: left;
        .img_box{
            width:23.5%;
            margin:0 0.5% 10px;
        }
    }

}
</style>
