<template>
    <div class="machining">
        <xc-banner :banner-id="id" :name="name"/>
        <div class="machining01">
            <div class="wrap">
                <div class="name">{{ content_one.title }}</div>
                <div class="title">{{ content_one.subTitle }}</div>
                <div class="content fix">
                    <div class="text l" v-html="content_one.content"></div>
                    <div class="img imgZoom r">
                        <img :src="$SYS.IMG_PREFIX + content_one.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="machining02">
            <div class="wrap">
                <div class="title">{{ content_two.title }}</div>
                <div class="content fix">
                    <div class="text r" v-html="content_two.content"></div>
                    <div class="img imgZoom l">
                        <img :src="$SYS.IMG_PREFIX + content_two.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="machining03 odd" v-if="">
            <crast-text v-for="(item, i) in content_three" :key="i" :item="item"/>
        </div>

        <div class="machining04">
            <center-text :item="content_four"/>
        </div>
        <div class="machining05" v-if="content_five.proList.length > 0">
            <pro-carousel :content="content_five" :id="id" :cat-id="catId" :channel-id="catId" :noChild="noChild"/>
            <div class="view_more" @click="toProduct"><span>VIEW MORE</span></div>
        </div>
        <div class="machining06">
            <pro-text :item="infoList[0]" proAlign="img-left"/>
        </div>
        <div class="machining07">
            <pro-text :item="infoList[1]"/>
        </div>
        <div class="machining08">
            <div class="wrap">
                <div class="title">
                    <div class="tit">{{ partner.title }}</div>
                    <div class="con">{{ partner.text }}</div>
                </div>
                <div class="content">
                    <partner/>
                </div>
            </div>
        </div>
        <div class="machining09">
            <to-contact/>
        </div>
    </div>
</template>

<script>
import {videoPlayer} from 'vue-video-player'
import 'video.js/dist/video-js.css'
import XcBanner from "../../components/xcBanner";
import CrastText from "/src/components/CrastText";
import ProText from "/src/components/ProText";
import ToContact from "/src/components/ToContact";
import Partner from "/src/components/Partner";
import CenterText from "/src/components/CenterText";
import ProCarousel from "/src/components/ProCarousel";

export default {
    name: "machining",
    components: {ProCarousel, CenterText, Partner, ToContact, ProText, CrastText, XcBanner},
    data() {
        return {
            id: this.$route.query.id,
            catId: this.$route.query.catId,
            checkCatId: this.$route.query.childCatId,
            name:this.$route.query.name,
            content_one: {},
            content_two: {},
            content_three: [],
            infoList: [],
            partner: {
                title: 'Our Partners',
                text: 'With our specialty in metal parts production, we have been serving many customers engaged in different industries like Home Appliances, Construction, Automobiles, Heavy Equipment, etc.'
            },
            content_four: {},
            content_five: {
                title: 'Products through custom CNC machining processes',
                proList: []
            },
            erjiList: [],
            imgList: [],
            noChild: false
        }
    },
    created() {
        this.getCraftListByCat()
        this.getCraftCatList(true)
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
        this.$http.get(`${this.$API.getPageByChannelId}?channelId=${this.id}`).then(res => {
            if (res.data.code === 200) {
                if (res.data.result.length > 0) {
                    this.content_one = res.data.result[0]
                    this.content_two = res.data.result[1]
                    this.content_three.push(res.data.result[2] || {icon: require('../../static/image/casting/casting_icon3.png')})
                    this.content_three.push(res.data.result[3] || {icon: require('../../static/image/casting/casting_icon4.png')})
                    this.content_three.push(res.data.result[4] || {icon: require('../../static/image/casting/casting_icon5.png')})
                    this.content_three.push(res.data.result[5] || {icon: require('../../static/image/casting/casting_icon6.png')})
                    this.content_four = res.data.result[6] || {}
                    this.infoList.push(res.data.result[7])
                    this.infoList.push(res.data.result[8])
                }
            }
        });

        if(this.catId === '1499327637997240322'){
            this.content_five.title = 'Casting and processing test equipment to ensure product quality'
        }

    },
    methods: {
        //获取工艺分类接口
        getCraftCatList(isFirst) {
            this.$http.get(`${this.$API.getCraftCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.length > 0) {
                        this.erjiList = res.data.result[0]
                        if (!this.erjiList.childList) {
                            this.noChild = true
                        }
                        //获取工艺栏目下层接口
                        this.$http.get(this.$API.getChannelList).then((res) => {
                            if (res.data.code === 200) {
                                this.craftList = res.data.result[1].childList[1]
                                if (this.erjiList.id === this.craftList.url.split('#')[1]) {
                                    this.erjiList.channelId = this.craftList.id
                                }

                                if (isFirst) {
                                    this.checkCatId = this.erjiList.id;
                                    this.channelId = this.erjiList.channelId;
                                    this.getCraftListByCat()
                                }
                            }
                        })

                    }
                }
            });
        },
        //同过工艺——产品分类来查询 产品
        getCraftListByCat() {
            this.$http.get(this.$API.getCraftListByCat, {params: {'catId': this.catId}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.content_five.proList = res.data.result.records
                    }
                }
            });
        },
        toProduct() {
            this.$router.push({path: '/capabilities/products', query: {id: this.id, catId: this.catId, childCatId: this.checkCatId, noChild:this.noChild, name:this.name}});
        }
    }
}
</script>

<style scoped lang="less">
@color: #51b953;
@-webkit-keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

@keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

.machining {
    .machining01 {
        margin: 60px 0;

        .name {
            color: @color;
            font-size: 18px;
        }

        .title {
            font-size: 33px;
            line-height: 1.2;
            color: #1d1d1d;
            font-weight: bold;
            margin-bottom: 20px;
        }

    }

    .machining02 {
        background: #eeeeee;
        padding: 60px 0;

        .title {
            text-align: center;
            font-size: 30px;
            color: #1d1d1d;
            margin-bottom: 25px;
        }
    }

    .machining01 .content, .machining02 .content {
        .text {
            width: 62%;
            color: #5a5a5a;
            line-height: 1.6;
        }

        .img {
            width: 34%;
            position: relative;

            img {
                width: 100%;
            }
        }
    }

    .machining01 .cover, .machining02 .cover {
        position: absolute;
        left: 30px;
        bottom: 30px;

        .dot {
            display: inline-block;
            width: 65px;
            z-index: 5;
            height: 65px;
            vertical-align: middle;
            background: url(../../static/image/about/about_play.png) no-repeat center;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                width: 90px;
                height: 90px;
                -webkit-box-shadow: inset #6fba2a 0 0 40px;
                box-shadow: inset #6fba2a 0 0 40px;
                border-radius: 50%;
                top: -11.5px;
                left: -11.5px;
                opacity: 0;
                -webkit-animation: warn-2 2s ease-out 1.1s infinite;
                animation: warn-2 2s ease-out 1.1s infinite;
            }

            &:before {
                content: '';
                position: absolute;
                width: 65px;
                height: 65px;
                -webkit-box-shadow: inset #fff 0 0 40px;
                box-shadow: inset #fff 0 0 40px;
                border-radius: 50%;
                top: 0;
                left: 0;
                opacity: 0;
                -webkit-animation: warn 2s ease-out .1s infinite;
                animation: warn 2s ease-out .1s infinite;
            }
        }

        span {
            display: inline-block;
            width: 60%;
            color: #ffffff;
            font-size: 24px;
            margin-left: 20px;
            vertical-align: middle;
            line-height: 32px;
        }
    }

    .machining03 {
        background-color: #eeeeee;
    }

    .machining08 {
        .title {
            text-align: center;

            .tit {
                font-size: 30px;
                color: #1d1d1d;
                margin-bottom: 15px;
            }

            .con {
                font-size: 17px;
                color: #5a5a5a;
            }

        }

        .content {
            margin: 40px 0 60px;
        }

    }
}

@media only screen and(max-width: 992px) {
    .machining .machining01 .cover span, .machining .machining02 .cover span {
        display: none;
    }

}

@media only screen and(max-width: 760px) {
    .machining {
        .machining01 {
            margin: 20px 0;

            .name {
                font-size: 14px;
            }

            .title {
                font-size: 20px;
            }
        }

        .machining01 .content .text,
        .machining02 .content .text {
            width: 100%;
            margin-bottom: 20px;
        }

        .machining01 .content .img,
        .machining02 .content .img {
            width: 100%;
        }

        .machining02 {
            padding: 20px 0 0;

            .title {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .machining08 {
            .title {
                .tit {
                    font-size: 20px;
                }

                .con {
                    font-size: 14px;
                }
            }

            .content {
                margin: 20px 0;
            }
        }
    }

}
</style>
