<template>
    <div class="prodetail">
        <xc-banner :banner-id="channelId" :name="name"/>
        <div class="prodetail01">
            <div class="wrap fix">
                <div class="right r">
                    <div class="protype">
                        <div class="select">
                            <div class="info" v-if="!noChild">PRODUCTS CATEGORY</div>
                            <div class="erji">
                                <el-collapse accordion>
                                    <el-collapse-item v-for="(item,i) in erjiList" :key="i">
                                        <template slot="title">
                                            <div class="title" @click="changePro(item)">
                                                    <span v-if="item.childList && item.childList.length > 0" class="icon">
                                                        <i class="iconfont jia icon-jiahao1"></i>
                                                        <i class="iconfont jian icon-jianhao"></i>
                                                    </span>
                                                <span>{{ item.enName }}</span>
                                            </div>
                                        </template>
                                        <div class="sanji" v-for="(sanji,i) in item.childList" :key="i" @click="changePro(sanji)" v-if="item.childList && item.childList.length > 0">
                                            {{ sanji.enName }}
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                        <div class="Brochures">
                            <div class="tit">
                                Brochures
                            </div>
                            <div class="con">
                                View our 2021 financial prospectus brochure for an ease to read guide on all of the services offers
                            </div>
                            <div class="btn" @click="$router.push({path:'/about',query:{id:'1483421350510473218'}})">COMPANY INFO</div>
                            <div class="btn" @click="$router.push({path:'/contact',query:{id:'1483421481972543490'}})">CONTACT</div>
                        </div>
                    </div>
                </div>
                <div class="left l">
                    <div class="content fix">
                        <div class="img l imgZoom">
                            <img :src="$SYS.IMG_PREFIX + detail.img" :alt="detail.name">
                        </div>
                        <div class="info r">
                            <div class="title">{{ detail.name }}</div>
                            <div class="text">{{ detail.model }}</div>
                            <div class="btns">
                                <a class="download" :href="detail.file">Download Price List</a>
                                <a class="contact" @click="$router.push({path:'/contact',query:{id:'1483421481972543490'}})">Contact Online</a>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <a-anchor :offsetTop="fixTop" :targetOffset="160">
                            <a-anchor-link href="#details" title="Overview"/>
                            <a-anchor-link href="#manufacturing" title="Feature"/>
                            <a-anchor-link href="#qc" title="Parameters"></a-anchor-link>
                        </a-anchor>
                        <div class="item" id="details">
                            <div class="title">Overview</div>
                            <div class="text" v-html="detail.content"></div>
                        </div>
                        <div class="item" id="manufacturing">
                            <div class="title">Feature</div>
                            <div class="text" v-html="detail.characteristic"></div>
                        </div>
                        <div class="item" id="qc">
                            <div class="title">Parameters</div>
                            <div class="text" v-html="detail.parameter"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="prodetail02">
            <div class="wrap">
                <div class="title">RELATED products</div>
                <div class="content">
                    <div class="swiper-wrapper">
                        <swiper :options="swiperOption">
                            <swiper-slide v-for="(item,i) in proList" :key="i" style="cursor:pointer;">
                                <div class="img imgZoom" @click="toDetail(item)">
                                    <div class="rect-100">
                                        <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                                    </div>
                                </div>
                                <div class="tit">{{ item.name }}</div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import XcBanner from "/src/components/xcBanner";
import ProType from "/src/components/ProType";
import 'swiper/dist/css/swiper.css'    //在全局没引入，这里记得要！
import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
    name: "prodetail",
    components: {
        ProType, XcBanner, swiper, swiperSlide
    },
    data() {
        return {
            id: this.$route.params.id,
            channelId: this.$route.params.channelId,
            catId: this.$route.params.catId,
            name: this.$route.params.name,
            checkCatId: this.$route.params.checkCatId,
            noChild: this.$route.query.noChild,
            detail: '',
            fixTop: 90,
            erjiList: [],
            swiperOption: {
                // 所有的参数同 swiper 官方 api 参数一样
                slidesPerView: '4',
                spaceBetween: 30,
                prevButton: '.swiper-button-prev',
                nextButton: '.swiper-button-next',
                // autoplay:3000,
            },
            routePrefix: '/industries',
            proList: []
        }
    },
    created() {
        this.routePrefix = '/' + this.$route.path.split('/')[1]
        if (this.routePrefix === '/industries' || this.routePrefix === '/product') {
            this.getProductCatList()
            this.getProductListByCat(this.checkCatId)
            this.getProductDetail()
        } else {
            this.getCraftCatList()
            this.getCraftListByCat(this.checkCatId)
            this.getCraftDetail()
        }

        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'

        let baseType = this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC
        if (!baseType) {
            this.swiperOption.slidesPerView = '2'
            this.swiperOption.spaceBetween = 20
            this.fixTop = 0
        }
    },
    methods: {
        //获取产品的详细信息接口
        getProductDetail() {
            this.$http.get(`${this.$API.getProductDetail}?id=${this.id}`).then(res => {
                if (res.data.code === 200) {
                    this.detail = res.data.result
                }
            });
        },
        //产品分类接口
        getProductCatList() {
            this.$http.get(`${this.$API.getProductCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    this.erjiList = res.data.result[0].childList
                }
            });
        },
        //产品列表接口
        getProductListByCat(catId) {
            this.$http.get(this.$API.getProductListByCat, {params: {'catId': catId}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.proList = res.data.result.records

                    }
                }
            });

        },
        //点击分类跳转到产品列表页面
        changePro(item) {
            if (!item.childList || item.childList.length < 0) {
                this.$router.push({path: this.routePrefix + '/products', query: {id: this.channelId, catId: this.catId, childCatId: item.id}})
            }

        },

        //点击产品跳转到产品详细页面
        toDetail(item) {
            this.$router.push({path: `${this.routePrefix}/${this.channelId}/${this.catId}/${item.id}/${this.checkCatId}/${item.name}`, query: {noChild: this.noChild}})
        },
        //获取工艺-产品的详细信息接口
        getCraftDetail() {
            this.$http.get(`${this.$API.getCraftDetail}?id=${this.id}`).then(res => {
                if (res.data.code === 200) {
                    this.detail = res.data.result
                }
            });
        },
        //产工艺-品分类接口
        getCraftCatList() {
            this.$http.get(`${this.$API.getCraftCatList}?id=${this.catId}`).then(res => {
                if (res.data.code === 200) {
                    this.erjiList = res.data.result[0].childList
                }
            });
        },
        //工艺-产品列表接口
        getCraftListByCat(catId) {
            this.$http.get(this.$API.getCraftListByCat, {params: {'catId': catId}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.proList = res.data.result.records
                    }
                }
            });

        },
    },
}
</script>

<style scoped lang="less">
.protype {

    .select {
        .info {
            font-size: 24px;
            color: #1e1e1e;
            font-weight: bold;
        }


        .erji {
            margin: 20px 0;

            .title {
                background: #344e6c;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
                position: relative;
                width: 100%;
                margin: 0 !important;
                text-align: left;

                .icon {
                    position: absolute;
                    left: 15px;
                    font-size: 16px;

                    .jian {
                        display: none;
                    }
                }

            }

            .is-active {
                .title {
                    .jia {
                        display: none;
                    }

                    .jian {
                        display: block;
                    }
                }
            }

            .sanji {
                margin-top: 10px;
                background: #7b8c9f;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
            }
        }
    }

    .Brochures {
        background: #88d24e;
        padding: 38px 20px 25px;

        .tit {
            font-size: 24px;
            color: #fff;
        }

        .con {
            font-size: 16px;
            color: #fff;
            line-height: 1.6;
            margin: 25px 0;
        }

        .btn {
            background: #ffffff;
            color: #737373;
            line-height: 60px;
            cursor: pointer;
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.prodetail01 {
    padding: 60px 0;

    .left {
        width: 69.2%;

        .content {
            margin-bottom: 60px;

            .img {
                width: 55.7%;

                img {
                    width: 100%;
                }
            }

            .info {
                width: 40.5%;

                .title {
                    font-size: 36px;
                    color: #1f1f1f;
                    font-weight: bold;
                    line-height: 1.2;
                    margin-bottom: 25px;
                }

                .text {
                    font-size: 14px;
                    color: #818181;
                    margin-bottom: 45px;
                }

                .btns {
                    a {
                        display: inline-block;
                        width: 150px;
                        line-height: 50px;
                        text-align: center;
                        transition: .5s ease;

                        &.download {
                            background: #6fba2a;
                            color: #fff;
                            margin-right: 15px;
                            margin-bottom: 10px;

                            &:hover {
                                background: #5d9d22;
                            }
                        }

                        &.contact {
                            border: 1px solid #dcdcdc;
                            color: #525252;

                            &:hover {
                                background: #5d9d22;
                                border-color: #5d9d22;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .container {


            .item {
                margin-bottom: 30px;
                margin-top: 35px;

                .title {
                    font-size: 30px;
                    color: #1e1e1e;
                    margin-bottom: 10px;

                }
            }
        }
    }

    .right {
        width: 26.5%;
    }
}

.prodetail02 {
    margin-bottom: 60px;

    .title {
        font-size: 36px;
        font-weight: bold;
        color: #88d24e;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .content {
        .tit {
            text-align: center;
            color: #1e1e1e;
            font-size: 18px;
            padding: 15px 0;
        }

        .img {
            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1240px) {


}

@media only screen and (max-width: 992px) {


}

@media only screen and (max-width: 760px) {
    .prodetail01 {
        padding: 20px 0;

        .left {
            width: 100%;

            .content {
                margin-bottom: 20px;

                .img {
                    width: 100%;
                    margin-bottom: 20px;
                }

                .info {
                    width: 100%;

                    .title {
                        font-size: 20px;
                    }

                    .text {
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                }
            }

            .container {
                .item {
                    margin-top: 0;
                    margin-bottom: 20px;

                    .title {
                        font-size: 20px;
                    }
                }
            }
        }

        .right {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .prodetail02 {
        margin-bottom: 20px;

        .title {
            font-size: 20px;
            margin-bottom: 20px;
        }

        .content {
            .tit {
                font-size: 14px;
            }
        }
    }

    .protype {
        .select {
            .info {
                font-size: 20px;
            }

            .erji .title {
                padding: 10px 20px;
                font-size: 15px;
            }
        }

        .Brochures {
            display: none;
            padding: 20px 10px;

            .tit {
                font-size: 18px;
            }

            .con {
                font-size: 14px;
                margin: 15px 0;
            }

            .btn {
                font-size: 14px;
                line-height: 45px;
            }
        }
    }

}
</style>
<style lang="less">
.protype {

    .select {
        .info {
            font-size: 24px;
            color: #1e1e1e;
            font-weight: bold;
        }


        .erji {
            margin: 20px 0;

            .title {
                background: #344e6c;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
                position: relative;
                width: 100%;
                margin: 0 !important;
                text-align: left;

                .icon {
                    position: absolute;
                    left: 15px;
                    font-size: 16px;

                    .jian {
                        display: none;
                    }
                }

            }

            .is-active {
                .title {
                    .jia {
                        display: none;
                    }

                    .jian {
                        display: block;
                    }
                }
            }

            .sanji {
                margin-top: 10px;
                background: #7b8c9f;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
            }
        }
    }

    .Brochures {
        background: #88d24e;
        padding: 38px 20px 25px;

        .tit {
            font-size: 24px;
            color: #fff;
        }

        .con {
            font-size: 16px;
            color: #fff;
            line-height: 1.6;
            margin: 25px 0;
        }

        .btn {
            background: #ffffff;
            color: #737373;
            line-height: 60px;
            cursor: pointer;
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.prodetail01 {
    .ant-anchor {
        border-bottom: 1px solid #eee;
    }

    .ant-anchor-ink {
        display: none;
    }

    .ant-affix {
        width: 100% !important;
    }

    .ant-anchor-link {
        padding: 10px 0;
        margin-right: 40px;
        display: inline-block;
        font-size: 24px;
        color: #595959;

        &.ant-anchor-link-active {
            border-bottom: 1px solid #6fba2a;

            & > .ant-anchor-link-title {
                color: #6fba2a;
            }


        }

        a:active, a:hover {
            color: #6fba2a;
        }
    }
}

.prodetail02 {
    .swiper-container {
        width: 100%;
    }

    .swiper-button-prev, .swiper-button-next {
        width: 45px;
        height: 83.7%;
        top: 0;
        margin-top: 0;
        background-size: cover;
    }

    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity: 0;
    }

    .swiper-button-next {
        background-image: url("../../static/image/next.png");
        background-color: #f5f5f5;
        right: -55px;
    }

    /*改变了颜色和加粗的样式*/

    .swiper-button-prev {
        background-image: url("../../static/image/prev.png");
        background-color: #f5f5f5;
        left: -55px;
    }

}

@media only screen and (max-width: 992px) {
    .prodetail02 {
        .swiper-button-next {
            right: -12px;
            background-color: transparent;
        }

        .swiper-button-prev {
            left: -12px;
            background-color: transparent;
        }
    }

}

@media only screen and (max-width: 760px) {
    .prodetail01 .ant-anchor {
        display: none;
    }

    .protype {
        .select {
            .info {
                font-size: 20px;
            }
        }

        .Brochures {
            padding: 20px 10px;

            .tit {
                font-size: 18px;
            }

            .con {
                font-size: 14px;
                margin: 15px 0;
            }

            .btn {
                font-size: 14px;
                line-height: 45px;
            }
        }
    }
}
</style>
