<template>
    <div v-if="item" class="proText" :class="proAlign">
        <div class="wrap fix">
            <div class="img ">
                <div class="imgZoom">
                    <!--<img :src="$SYS.IMG_PREFIX + item.img" :alt="item.title">-->
                    <el-image :src="$SYS.IMG_PREFIX + item.img" :alt="item.title"></el-image>
                </div>
                <div class="sub_title">{{item.sub_title}}</div>
            </div>
            <div class="info">
                <div class="tit">{{ item.title }}</div>
                <div class="text">{{ item.summary }}</div>
                <div class="content" v-html="item.content">
                    <!--<li v-for="(item,i) in item.content" :key="i">-->
                    <!--    <div class="number">{{ i + 1 }}</div>-->
                    <!--    <div class="desc">{{ item }}</div>-->
                    <!--</li>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProText",
    props: ['item', 'proAlign']
}
</script>

<style scoped lang="less">
@color: #51b953;
.proText {
    padding: 55px 0;

    .img {
        float: right;
        width: 39%;

        img {
            width: 100%;
        }
    }

    .info {
        float: left;
        width: 56.6%;

        .tit {
            font-size: 33px;
            color: #1e1e1e;
            font-weight: bold;
            line-height: 1.2;
        }

        .text {
            font-size: 14px;
            color: #595959;
            line-height: 1.4;
            margin: 30px 0;
        }

        .content {
            .item {
                position: relative;
                padding-left: 50px;
                margin-bottom: 25px;

                .number {
                    width: 30px;
                    height: 30px;
                    background: @color;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 20px;
                    color: #ffffff;
                }
            }
        }
    }
    &.img-left {
        background: #eeeeee;
        .img {
            float: left;
        }
        .info{
            float:right;
        }
    }
}
@media only screen and (max-width: 1240px) {
    .proText .info .tit {
        font-size: 30px;
    }
}
@media only screen and (max-width: 992px) {
    .proText {
        .img {
            width: 100%;
            margin-bottom: 20px;
        }

        .info {
            width: 100%
        }
    }

}
@media only screen and (max-width: 760px) {
    .proText {
        padding: 20px 0;

        .info {
            .tit {
                font-size: 20px;
            }

            .text {
                font-size: 14px;
                margin: 20px 0;
            }

            ul {
                li {
                    margin-bottom: 20px;
                    padding-left: 40px;
                }
            }
        }
    }

}

</style>
