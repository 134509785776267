<template>
    <div class="about">
        <xc-banner :banner-id="id" name="About"/>
        <div class="about01" id="introduction">
            <div class="wrap fix">
                <div class="left imgZoom l wow fadeInLeftBig" data-wow-duration="1s">
                    <img :src="$SYS.IMG_PREFIX + aboutList[0].img" alt="aboutList[0].title">
                    <div class="cover">
                        <div class="dot" @click="openVideo"></div>
                        <span>View Our Video</span>
                    </div>
                </div>
                <div class="right wow fadeInRightBig" data-wow-duration="1s">
                    <div class="title">
                        <div class="tit">{{ aboutList[0].subTitle }}</div>
                        <div class="con">{{ aboutList[0].title }}</div>
                    </div>
                    <div class="text" v-html="aboutList[0].content"></div>
                </div>
            </div>

        </div>
        <transition name="fade">
            <div class="video_box" v-show="videoShow">
                <video-player class="video-player vjs-custom-skin"
                              ref="videoPlayer"
                              :playsinline="true"
                              :options="playerOptions">
                </video-player>
                <div class="close" @click="closeVideo"><i class="iconfont icon-018cross04"></i></div>
                <div class="cover" @click="closeVideo"></div>
            </div>
        </transition>
        <div class="about02" id="advantage">
            <div class="wrap fix">
                <div class="left l wow fadeInLeftBig" data-wow-duration="1s">
                    <div class="title">
                        <div class="tit">{{ aboutList[1].subTitle }}</div>
                        <div class="con">{{ aboutList[1].title }}</div>
                        <div class="text">{{ aboutList[1].summary }}</div>
                    </div>
                    <div class="iconList fix">
                        <div class="item l fix" v-for="item in iconList" :key="item.name" @click="showInfo(item.name)" :class="[item.name === company[0].title ? 'active' : '']">
                            <div class="img l">
                                <img :src="item.url" :alt="item.name">
                            </div>
                            <div class="name l">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="text" v-html="aboutList[1].content"></div>
                    <div class="btn" @click="$router.push({path:'/contact',query:{id:'1483421481972543490'}})">Contact Us</div>
                </div>
                <div class="right r imgZoom wow fadeInRightBig" data-wow-duration="1s">
                    <div class="img" v-if="company[0]">
                        <img :src="$SYS.IMG_PREFIX + company[0].img" alt="about">
                        <div class="cover">
                            <div class="box">
                                <div class="content" v-html="company[0].content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about03" id="evaluation">
            <Comment/>
        </div>
        <div class="about04" id="customers">
            <div class="wrap">
                <div class="title">
                    <div class="tit">TRUSTED PARTNER</div>
                    <div class="con">Our Partners</div>
                </div>
                <div class="content">
                    <partner/>
                </div>
            </div>
        </div>
        <div class="about05" id="message">
            <div class="wrap fix">
                <div class="left l">
                    <div class="tit">New projects or questions？</div>
                    <div class="con">Zhang Group dedicates itself to creating value for customers by supplying quality metal products and developing long-term partnerships.</div>
                </div>
                <div class="right r wow fadeInRightBig" data-wow-duration="1s">
                    <div class="title">We will answer your message shortly!</div>
                    <div class="form">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline-message="inlineMessage" class="demo-ruleForm">
                            <div class="item fix">
                                <el-form-item prop="name">
                                    <el-input v-model="ruleForm.name" placeholder="Your Name"></el-input>
                                </el-form-item>
                                <el-form-item prop="email">
                                    <el-input v-model="ruleForm.email" placeholder="Your Email"></el-input>
                                </el-form-item>
                            </div>
                            <div class="item fix">
                                <el-form-item prop="country">
                                    <el-input v-model="ruleForm.country" placeholder="Your Country"></el-input>
                                </el-form-item>
                                <el-form-item prop="subject">
                                    <el-input v-model="ruleForm.subject" placeholder="Your Subject"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item prop="message">
                                <el-input type="textarea" v-model="ruleForm.message" placeholder="Your Message"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')">SEND MESSAGE</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WOW from 'wowjs'
import XcBanner from "/src/components/xcBanner";
import {videoPlayer} from 'vue-video-player'
import 'video.js/dist/video-js.css'
import Comment from "/src/components/Comment";
import Partner from "/src/components/Partner";
import $ from 'jquery'

export default {
    name: "about",
    components: {Partner, Comment, XcBanner, videoPlayer},
    data() {
        let checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error('The mailbox can\'t be empty'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('Please enter the correct email format'))
                }
            }, 100)
        }
        return {
            id: this.$route.query.id,
            aboutList: [],
            aboutText: '',
            name: 'About',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false,  // 如果为true,浏览器准备好时开始回放
                muted: false,     // 默认情况下将会消除任何音频。
                loop: false,      // 是否视频一结束就重新开始。
                preload: 'auto',  // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9',  // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true,  // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4",  // 类型
                    src: '111'             // url地址
                }],
                poster: '',  // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试',  // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,           // 当前时间和持续时间的分隔符
                    durationDisplay: true,       // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true       // 是否显示全屏按钮
                },

            },
            videoShow: false,
            iconList: [
                {
                    name: 'Zhangshi Group',
                    url: require('../static/image/about/about02_icon1.png')
                },
                {
                    name: 'Zhangshi Machinery',
                    url: require('../static/image/about/about02_icon2.png')
                },
                {
                    name: 'Zhangshi Shangjia',
                    url: require('../static/image/about/about02_icon3.png')
                },
                {
                    name: 'Haogang Technology',
                    url: require('../static/image/about/about02_icon4.png')
                },
                {
                    name: 'International Trade',
                    url: require('../static/image/about/about02_icon5.png')
                },
                {
                    name: 'Romania',
                    url: require('../static/image/about/about02_icon6.png')
                }
            ],
            ruleForm: {
                name: '',
                email: '',
                country: '',
                subject: '',
                message: ''
            },
            inlineMessage: false,
            rules: {
                name: [
                    {required: true, message: 'Please enter your name', trigger: 'blur'},
                    {max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: 'Please enter your email', trigger: 'blur'},
                    {validator: checkEmail, trigger: 'blur'}

                ],
                country: [
                    {required: true, message: 'Please enter your country', trigger: 'blur'},
                    {max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                object: [
                    {required: false, message: 'Please enter your object', trigger: 'blur'},
                ],
                message: [
                    {required: false, message: 'Please enter message', trigger: 'blur'}
                ]
            },
            companyList: [],
            company: [],
        }
    },
    methods: {
        getPageByChannelId() {
            this.$http.get(`${this.$API.getPageByChannelId}?channelId=${this.id}`).then(res => {
                if (res.data.code === 200) {
                    this.aboutList = res.data.result
                    if (res.data.result.length > 0) {
                        this.playerOptions.sources[0].src = res.data.result[0].link
                        this.companyList = res.data.result.slice(2)
                        this.company = [res.data.result[2]]
                    }
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http.post(this.$API.postForm, {name: this.ruleForm.name, email: this.ruleForm.email, country: this.ruleForm.country, subject: this.ruleForm.subject, message: this.ruleForm.message}).then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success({
                                message: res.data.result,
                                offset: 100
                            });
                        }
                    })
                } else {
                    this.$message.error({
                        message: 'error submit',
                        offset: 100
                    });
                    return false;
                }
            });
        },
        openVideo() {
            this.videoShow = true;
            this.$refs.videoPlayer.player.play()   // 播放
        },
        closeVideo() {
            this.videoShow = false;
            this.$refs.videoPlayer.player.pause()  // 暂停
        },
        showInfo(title) {
            this.company = this.companyList.filter((item) => item.title === title)
        }
    },
    created() {
        this.getPageByChannelId()
        new WOW.WOW().init()
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
    },
    mounted() {
        setTimeout(() => {
            if (this.$route.params.toAnchor) {
                let anchor = document.getElementById(this.$route.params.toAnchor); // 参数为要跳转到的元素id
                let scrollTop = anchor.offsetTop - document.querySelector('.headerContent').clientHeight - 10
                $('body,html').animate({scrollTop: scrollTop}, 500)
            }
        }, 1000)
    }
}
</script>

<style scoped lang="less">
@color: #6fba2a;
@-webkit-keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

@keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

.about01 {
    margin: 50px 0;

    .left {
        width: 43.5%;
        position: relative;
        margin-right: 2%;

        img {
            width: 100%;
        }

        .cover {
            position: absolute;
            left: 30px;
            bottom: 30px;

            .dot {
                display: inline-block;
                width: 65px;
                z-index: 5;
                height: 65px;

                background: url(../static/image/about/about_play.png) no-repeat center;
                cursor: pointer;

                &:after {
                    content: '';
                    position: absolute;
                    width: 90px;
                    height: 90px;
                    -webkit-box-shadow: inset #6fba2a 0 0 40px;
                    box-shadow: inset #6fba2a 0 0 40px;
                    border-radius: 50%;
                    top: -11.5px;
                    left: -11.5px;
                    opacity: 0;
                    -webkit-animation: warn-2 2s ease-out 1.1s infinite;
                    animation: warn-2 2s ease-out 1.1s infinite;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 65px;
                    height: 65px;
                    -webkit-box-shadow: inset #fff 0 0 40px;
                    box-shadow: inset #fff 0 0 40px;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    -webkit-animation: warn 2s ease-out .1s infinite;
                    animation: warn 2s ease-out .1s infinite;
                }
            }

            span {
                color: #ffffff;
                font-size: 24px;
                margin-left: 20px;
                vertical-align: top;
                line-height: 65px;
            }
        }


    }

    .right {
        .title {
            padding-bottom: 45px;

            .tit {
                color: @color;
                font-size: 20px;
                margin: 20px 0;
            }

            .con {
                font-size: 42px;
                font-weight: bold;
                color: #131313;
                line-height: 1;
            }
        }

        .text {
            font-size: 15px;
            color: #5a5a5a;
            line-height: 1.6;
        }
    }
}

.about02 {
    background: #f5f5f5;
    padding: 65px 0;

    .left {
        width: 52%;

        .title {
            .tit {
                color: @color;
                font-size: 20px;

            }

            .con {
                font-size: 42px;
                color: #131313;
                font-weight: bold;
                line-height: 1;
                margin: 20px 0 30px;
            }

            .text {
                color: #464646;
                font-size: 16px;
                line-height: 1.6;
            }
        }

        .iconList {
            margin: 20px 0 0;

            .item {
                width: 50%;
                margin-bottom: 25px;
                cursor: pointer;

                .img {
                    width: 64px;
                }

                .name {
                    color: #151515;
                    font-size: 22px;
                    margin-left: 15px;
                    width: 70%;
                    line-height: 60px;
                    transition: .5s ease;

                }

                &.active {
                    .name {
                        color: @color;
                    }
                }

            }
        }

        .text {
            color: #464646;
            font-size: 16px;
            line-height: 1.6;
        }

        .btn {
            background: @color;
            width: 170px;
            line-height: 50px;
            color: #ffffff;
            font-size: 15px;
            text-align: center;
            margin-top: 60px;
            cursor: pointer;
            transition: .5s ease;

            &:hover {
                background: #3ca347;
            }
        }
    }

    .right {
        width: 37%;
        cursor: pointer;

        .img {
            height: 0;
            padding-bottom: 126%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .cover {
                position: absolute;
                opacity: 0;
                transition: .5s ease;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                top: 0;
                left: 0;

                .box {
                    padding: 20px;
                    color: #fff;
                    height: 100%;
                    overflow-y: auto;
                }
            }
        }

        &:hover {
            .img {
                .cover {
                    opacity: 1;
                }
            }
        }

    }
}

.about03 {
    background: url('../static/image/about/about03_bg.jpg') no-repeat center;
    background-size: cover;
}

.about04 {
    padding: 55px 0 66px;

    .title {
        text-align: center;

        .tit {
            color: @color;
            font-size: 20px;

        }

        .con {
            font-size: 42px;
            color: #131313;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 40px;
        }
    }
}

.about05 {
    background: url("../static/image/about/about05_bg.jpg") no-repeat center;
    background-size: cover;
    padding: 70px 0 110px;

    .left {
        width: 50%;
        color: #ffffff;
        padding-top: 90px;

        .tit {
            font-size: 42px;
            font-weight: bold;
            margin-bottom: 32px;
        }

        .con {
            font-size: 18px;
            line-height: 2;
        }
    }

    .right {
        background: #ffffff;
        width: 37%;
        padding: 70px 55px;

        .title {
            font-size: 22px;
            color: #1f1f1f;
            margin-bottom: 45px;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@media only screen and (max-width: 1800px) {
    .about01 .right .title .tit {
        margin: 0;
    }
}

@media only screen and (max-width: 1366px) {
    .about02 .left .iconList .item .name {
        font-size: 20px;
    }

}

@media only screen and (max-width: 1240px) {
    .about02 .left .title .con {
        font-size: 40px;
    }

    .about05 .right {
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 992px) {
    .about01 {
        .left {
            width: 100%;
            margin-bottom: 20px;

        }

        .right {
            width: 100%;
        }
    }

    .about02 {
        .left {
            width: 100%;
            margin-bottom: 20px;

        }

        .right {
            width: 100%;
        }
    }

    .about05 .right {
        width: 45%;
    }
}

@media only screen and (max-width: 760px) {
    .about01 {
        margin: 20px 0;

        .right {
            .title {
                padding-bottom: 20px;

                .tit {
                    font-size: 16px;
                }

                .con {
                    font-size: 20px;
                }
            }

            .text {
                font-size: 14px;
            }
        }
    }

    .about02 {
        padding: 20px 0;

        .left {
            .title {

                .tit {
                    font-size: 16px;
                }

                .con {
                    font-size: 20px;
                    margin: 10px 0 20px;
                }

                .text {
                    font-size: 14px;
                }


            }

            .iconList {
                .item {
                    .img {
                        width: 100%;
                        text-align: center;
                    }

                    .name {
                        width: 100%;
                        font-size: 16px;
                        margin-left: 0;
                        text-align: center;
                    }
                }
            }

            .text {
                font-size: 14px;
            }

            .btn {
                margin: 0 auto;
            }
        }
    }

    .about04 {
        padding: 20px 0;

        .title {
            .tit {
                font-size: 14px;
            }

            .con {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .about05 {
        padding: 20px 0;

        .left {
            width: 100%;
            padding: 0 20px;

            .tit {
                font-size: 20px;
                margin-bottom: 10px;
            }

            .con {
                font-size: 16px;
                line-height: 1.4;
            }
        }

        .right {
            width: 100%;
            margin-top: 20px;
            padding: 20px;

            .title {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }

}
</style>
<style lang="less">
@color: #6fba2a;
.video_box {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    overflow: hidden;
    z-index: 1000;
    //background: rgba(0, 0, 0, 1);

    .video-player {
        width: 100%;
        height: 100%;
        display: block;
    }

    .video-js {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .close {
        cursor: pointer;
        position: fixed;
        right: 10%;
        top: 10%;
        color: #ffffff;

        .iconfont {
            font-size: 35px;
        }
    }

    .cover {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: -1;
        position: fixed;
    }
}

.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.video-js .vjs-icon-placeholder {
    width: 100%;
    height: 100%;
    display: block;
}

.about05 {
    .el-input__inner {
        background: #f6f6f6;
        line-height: 50px;
        height: 50px;
        border-radius: 0;
    }

    .el-textarea__inner {
        background: #f6f6f6;
        height: 145px;
        border-radius: 0;
    }

    .el-button {
        width: 47%;
        border: none;
        border-radius: 0;
        background: @color;
        display: block;
        padding: 0;
        line-height: 50px;
        height: 50px;
        font-size: 15px;
        transition: .5s ease;

        &:focus, &:hover {
            background: #3ca347;
        }
    }

    .item {
        .el-form-item {
            width: 48%;
            float: left;
        }

        .el-form-item:nth-child(2) {
            float: right;
        }
    }
}

@media only screen and (max-width: 760px) {
    .about05 {
        .el-button {
            margin: 0 auto;

        }
    }
}

</style>
