<template>
    <div id="app">
        <!--<router-view name="Header"/>-->
        <Header/>
        <router-view class="min-height" :key="key"/>
        <Footer/>
    </div>
</template>

<style lang="less">
@import "common/css/iconfont.css";
@import "common/css/reset.css";

</style>
<script>
import Header from "/src/components/Header";
import Footer from "/src/components/Footer";
export default {
    components: {Footer, Header},
    computed: {
        key() {
            return this.$route.name !== undefined? this.$route.name + new Date(): this.$route + new Date()
        }
    }
}
</script>
