<template>
    <div class="footer">
        <div class="wrap">
            <div class="footer_top fix">
                <div class="left l">
                    <img src="../assets/logo.png" alt="">
                    <div class="inner">
                        <div class="text">{{ text }}</div>
                        <ul class="links fix">
                            <li><a :href="$SYS.LINK_FACEBOOK"><i class="iconfont icon-facebook-fill"></i></a></li>
                            <li><a :href="$SYS.LINK_TTWW"><i class="iconfont icon-ttww"></i></a></li>
                            <li><a :href="$SYS.LINK_LINKIN"><i class="iconfont icon-linkedin"></i></a></li>
                            <li><a :href="$SYS.LINK_INSTAGRAM"><i class="iconfont icon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="right l fix">
                    <dl class="dl1">
                        <dt>About</dt>
                        <dd v-for="(item,i) in company" @click="toAbout(item)" :key="i">{{item.title}}</dd>
                    </dl>
                    <dl class="dl2">
                        <dt>Industries</dt>
                        <dd v-for="(item,i) in productNav" @click="toProduct(item)" :key="i">{{item.name}}</dd>
                    </dl>
                    <dl class="dl3">
                        <dt>Contact</dt>
                        <dd>
                            <a :href="'tel:'+ $SYS.COMMON_TEL">
                                <i class="iconfont icon-dianhua1"></i>
                                {{$SYS.COMMON_TEL}}
                            </a>
                        </dd>
                        <dd>
                            <a :href="'mailto:'+$SYS.COMMON_EMAIL">
                                <i class="iconfont icon-youxiang2"></i>
                                {{$SYS.COMMON_EMAIL}}
                            </a>
                        </dd>
                        <dd>
                            <i class="iconfont icon-dizhi2"></i>
                            {{$SYS.COMMON_ADDRESS}}
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="footer_bottom">
                <div class="line"></div>
                <div class="copyright">{{ copyright }}</div>
                <div @click="goTop" class="gotop" v-show="toTopShow"><span><i class="iconfont icon-fanhuidingbu1"></i></span></div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'Footer',
    data() {
        return {
            toTopShow: false,
            copyright: 'Copyright © 2018-2021 All Rights Reserved.',
            text: 'Continue to be better and stronger, and continue to extend to the depths of the industrial chain.',
            company:[
                {
                    title:'Profile',
                    toAnchor:'introduction'
                },
                {
                    title:'Core Values',
                    toAnchor:'advantage'
                },
                {
                    title:'Evaluation',
                    toAnchor:'evaluation'
                },
                {
                    title:'Customers',
                    toAnchor:'customers'
                },
                {
                    title:'Online Message',
                    toAnchor:'Message'
                }
            ],
            productNav:[],
            craftNav:[]
        }
    },
    created() {
        this.$http.get(this.$API.getChannelList).then((res) => {
            if(res.data.code === 200){
                this.productNav = res.data.result[2].childList
                this.craftNav = res.data.result[0].childList
            }
        })
        this.$nextTick(function () {
            let that = this
            $(window).scroll(function () {
                that.toTopShow = $(window).scrollTop() > 200
            })

        })
    },
    methods: {
        goTop() {
            $('html,body').stop(1).animate({scrollTop: '0'}, 300);
        },
        toAbout(item){
            this.$router.push({name:'About',query: {toAnchor: item.toAnchor,id:'1483421350510473218'}, params: {toAnchor: item.toAnchor,id:'1483421350510473218'}})
            // this.$router.push({path:'/about',query: {toAnchor: item.toAnchor}, params: {toAnchor: item.toAnchor}})
        },
        toProduct(item){
            let idArr = item.url.split("#");
            let query = {id: item.id,name:item.name}
            if (idArr.length > 1) {
                query.catId = idArr[1]
            }
            this.$router.push({path:idArr[0], query: query});
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@color: #51b953;
.footer {
    background: url("../static/image/footer_bg.jpg") no-repeat center;
    background-size: cover;

    .footer_top {
        padding: 110px 0;

        .left {
            width: 25%;

            .inner {
                padding-left: 19%;

                .text {
                    font-size: 16px;
                    color: #8d91a2;
                    line-height: 36px;
                    margin: 15px 0 25px;
                }

                .links {
                    li {
                        float: left;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        background-color: #ffffff;
                        margin-right: 10px;
                        transition: .5s ease;

                        .iconfont {
                            color: #1b1f2e;
                        }

                        a {
                            display: block;
                        }

                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }

        .right {
            width: 75%;

            dl {
                float: left;
                margin-left: 9%;
                color: #ffffff;
                font-size: 24px;

                dt {
                    margin-bottom: 35px;
                }

                dd {
                    color: #8d91a2;
                    font-size: 16px;
                    margin-bottom: 15px;
                    transition: .5s ease;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                    }

                    .iconfont {
                        color: @color;
                    }
                }

                a {
                    color: #8d91a2;

                    &:hover {
                        color: #fff;
                    }
                }
                &.dl3{
                    width:36%;
                }
            }
        }
    }

    .footer_bottom {
        .line {
            margin: 0 auto;
            width: 90%;
            height: 1px;
            background-color: #2d313e;
        }

        .copyright {
            color: #ffffff;
            font-size: 16px;
            opacity: 0.32;
            text-align: center;
            padding: 30px 0;
        }

        .gotop {
            cursor: pointer;
            position: fixed;
            right: 50px;
            bottom: 25px;
            width: 45px;
            height: 45px;
            background-color: #444444;
            text-align: center;
            z-index: 2;

            span {
                border: 4px solid #fff;
                border-radius: 50%;
                display: inline-block;
                color: #fff;
                margin-top: 10px;
                width: 27px;
                height: 27px;

                .iconfont {
                    line-height: 18px;
                    font-size: 18px;
                    vertical-align: top;
                }
            }
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    top: -100%;
}

@media only screen and (max-width: 1240px) {
    .footer {
        .footer_top {
            .left {
                width: 30%;

                .inner {
                    .links {
                        li {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }

            .right {
                width: 70%;

                dl {
                    margin-left: 6%;
                }
            }
        }
    }

}

@media only screen and (max-width: 992px) {
    .footer .footer_top .left {
        display: none
    }

    .footer .footer_top .right {
        width: 100%;
    }
}

@media only screen and (max-width: 760px) {
    .footer {
        position: static;

        .footer_top {
            display: none
        }

        .footer_bottom {
            .copyright {
                font-size: 14px;
                padding: 15px 0;
            }

            .gotop {
                position: fixed;
                bottom: 18px;
                right: 20px;
                width: 32px;
                height: 32px;

                span {
                    margin-top: 3px;
                }
            }
        }

    }

}
</style>
