import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '/src/views/home'
import knowledge from '/src/views/knowledge'
import contact from '/src/views/contact'
import about from '/src/views/about'
// 工艺
import casting from '/src/views/capabilities/casting'
import machining from '/src/views/capabilities/machining'
import processdetail from "/src/views/capabilities/processdetail";
// 行业
import product from "/src/views/industries/product"
import prodetail from "/src/views/industries/prodetail";

Vue.use(VueRouter)

//解决vue路由重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: home,
    },
    {
        path: '/capabilities/casting',  //工艺-铸造
        name: 'Metal Casting',
        component: casting,
    },
    {
        path: '/capabilities/machining', //工艺-机械加工
        name: 'Metal Machining',
        component: machining,
    },
    {
        path: '/capabilities/processdetail',  //工艺-铸造-铸造二级
        name: 'Detail',
        component: processdetail,
    },
    {
        path: '/capabilities/products', //产品列表
        name: 'Industries',
        component: product,
    },
    {
        path: '/capabilities/:channelId/:catId/:id/:checkCatId/:name', //产品详细
        name: 'Prodetail',
        component: prodetail,
    },
    {
        path: '/industries/products', //产品列表
        name: 'Industries',
        component: product,
    },
    {
        path: '/industries/:channelId/:catId/:id/:checkCatId/:name', //产品详细
        name: 'Prodetail',
        component: prodetail,
    },
    {
        path: '/product/products', //产品列表
        name: 'Industries',
        component: product,
    },
    {
        path: '/product/:channelId/:catId/:id/:checkCatId/:name', //产品详细
        name: 'Prodetail',
        component: prodetail,
    },
    {
        path: '/knowledge', //知识问答
        name: 'Knowledge',
        component: knowledge
    },
    {
        path: '/contact',   //联系我们
        name: 'Contact Us',
        component: contact
    },
    {
        path: '/about',    //关于我们
        name: 'About',
        component: about
    },
]
// 不需要从顶部开始的页面
const NO_NEED_TO_TOP = ['About']
const router = new VueRouter({
    mode: 'history',
    routes
})
router.afterEach((to, from, next) => {
    if (NO_NEED_TO_TOP.indexOf(to.name) < 0 ) {
        window.scrollTo(0, 0);
    }
    // 或
    // window.scroll(0, 0);
});

export default router
