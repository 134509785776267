<template>
    <div class="home">
        <div class="ibanner">
            <el-carousel trigger="click" :autoplay="autoplay" :height="imgHeight">
                <el-carousel-item v-for="item in ibannerList" :key="item.index">
                    <img :ref="imgHeight" :src="$SYS.IMG_PREFIX + item.img" alt="" class="ibanner_img"/>
                    <!--<el-image :ref="imgHeight" :src="$SYS.IMG_PREFIX + item.img" alt="" :title="item.title"></el-image>-->
                    <div class="intro">
                        <div class="wrap">
                            <div class="title">{{ item.title }}</div>
                            <div class="big_title">{{ item.subTitle }}</div>
                            <div class="text">{{ item.summary }}</div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="ipart01">
            <div class="wrap">
                <div class="content fix wow slideInUp" data-wow-duration="1s">
                    <div class="img l">
                        <img src="../static/image/index/ipart01_1.png" alt="">
                    </div>
                    <div class="text l">
                        Do You Have A Metal Part
                        We Can Help With?
                    </div>
                    <div @click="toContact" class="btn l">GET A FREE QUOTE</div>
                </div>
            </div>
        </div>
        <div class="ipart02">
            <div class="wrap">
                <div class="common-intro">
                    <div class="tit wow fadeInUp" data-wow-duration="1s">{{ ipart02List.title }}</div>
                    <div class="con wow fadeInUp" data-wow-duration="1.2s">{{ ipart02List.summary }}</div>
                </div>
                <div class="content fix">
                    <div class="left_content l imgZoom wow fadeInLeftBig" data-wow-duration="1s">
                        <img :src="productList[0].img" :alt="productList[0].name">
                        <div class="mask">
                            <img src="../static/image/index/ipart02_1_cover.png" alt="casting">
                        </div>
                        <div class="cover">
                            <div class="title">{{ productList[0].name }}</div>
                            <div class="text">{{ productList[0].text }}</div>
                            <div class="btn" @click="toProduct(productList[0])">{{ productList[0].btn }}</div>
                        </div>
                    </div>
                    <div class="right_content r wow fadeInRightBig" data-wow-duration="1s">
                        <div v-for="item in productList.slice(1,4)" :key="item.name" @click="toProduct(item)" class="item imgZoom fix">
                            <div class="title l">
                                <div class="t">{{ item.name }}</div>
                            </div>
                            <div class="img r">
                                <img :src="item.img" :alt="item.name">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ipart03">
            <div class="inner">
                <div class="wrap">
                    <div class="title">About ZhangShi Group</div>
                    <div class="content fix">
                        <div class="item item1 wow bounceInUp" data-wow-duration="1s">
                            <div class="img"></div>
                            <div class="number">
                                <animate-number ref="num1" from="0" to="2010" duration="2000"></animate-number>
                            </div>
                            <div class="text">creation date</div>
                        </div>
                        <div class="item item2 wow bounceInUp" data-wow-duration="1.2s">
                            <div class="img"></div>
                            <div class="number">
                                <animate-number ref="num2" from="0" to="800" duration="2000"></animate-number>
                                +
                            </div>
                            <div class="text">employees</div>
                        </div>
                        <div class="item item3 wow bounceInUp" data-wow-duration="1.5s">
                            <div class="img"></div>
                            <div class="number">
                                <animate-number ref="num3" from="0" to="45" duration="2000"></animate-number>
                            </div>
                            <div class="text">All Patents</div>
                        </div>
                        <div class="item item4 wow bounceInUp" data-wow-duration="1.7s">
                            <div class="img"></div>
                            <div class="number">
                                <animate-number ref="num4" from="0" to="100" duration="2000"></animate-number>
                                +
                            </div>
                            <div class="text">r&d team</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ipart04">
            <div class="wrap">
                <div class="common-intro">
                    <div class="tit wow fadeInUp" data-wow-duration="1s">{{ipart04List.title}}</div>
                    <div class="con wow fadeInUp" data-wow-duration="1.2s">{{ipart04List.summary}}</div>
                </div>
                <div class="content fix">
                    <div v-for="(item,index) in industryList" :key="index" class="item l wow bounceInUp" :data-wow-duration="item.time">
                        <div class="inner">
                            <div class="img">
                                <img :src="item.img" alt="item.title">
                            </div>
                            <div class="cover">
                                <div class="box">
                                    <div class="icon">
                                        <img :src="item.icon" alt="item.title">
                                    </div>
                                    <div class="title">{{ item.name }}</div>
                                    <div class="con">
                                        <!--<div class="sub_link" v-for="link in item.childList" @click="toCheckProduct(item,link)">-->
                                        <!--    {{ link.enName }}-->
                                        <!--</div>-->
                                    </div>
                                    <div class="line"></div>
                                    <div class="btn" @click="toProduct(item)">Find Products ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ipart05">
            <div class="wrap">
                <div class="title">How To Work With Us</div>
                <div class="content fix">
                    <div v-for="item in process" class="item l wow bounceInUp" :data-wow-duration="item.time">
                        <div class="inner">
                            <div class="icon fix">
                                <img class="l" :src="item.url" :alt="item.name">
                            </div>
                            <div class="info">
                                <div class="tit">{{ item.name }}</div>
                                <div class="con">{{ item.con }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ipart06">
            <comment/>
        </div>
        <div class="ipart07">
            <div class="wrap">
                <div class="common-intro">
                    <div class="tit wow fadeInUp" data-wow-duration="1s">Core Values</div>
                    <div class="con wow fadeInUp" data-wow-duration="1.2s">QTST is the core values that set us apart from the competition. The four letters present four words as follows.</div>
                </div>
                <div class="content fix">
                    <div v-for="(item, i) in coreList" :key="i" class="item l wow bounceInUp" :data-wow-duration="item.time">
                        <div class="inner">
                            <div class="icon">
                                <img :src="item.img" :alt="item.title">
                            </div>
                            <div class="title">{{ item.title }}</div>
                            <div class="text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ipart08">
            <div class="wrap">
                <div class="title">Our Partners</div>
                <div class="text">With our specialty in metal parts production, we have been serving many customers engaged in different industries like Home Appliances, Construction, Automobiles, Heavy Equipment, etc.</div>
                <div class="content">
                    <partner/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import WOW from 'wowjs'
import comment from '../components/Comment.vue'
import partner from '../components/Partner.vue'

export default {
    name: 'home',
    components: {
        comment,
        partner
    },
    data() {
        return {
            ibannerList: [],
            imgHeight: '850px',
            autoplay: true,
            ipart02List: {},
            ipart04List:{},
            productList: [
                {
                    img: require('../static/image/index/ipart02_1.jpg'),
                    name: 'Metal Casting',
                    text: 'Production automation, annual casting capacity to reach the world\'s leading level',
                    btn: 'MORE DETAILS',
                    url: ''
                },
                {
                    img: require('../static/image/index/ipart02_2.png'),
                    name: 'Metal Machining',
                    utl: ''
                },
                {
                    img: require('../static/image/index/ipart02_3.png'),
                    name: 'Sheet metal',
                    url: ''
                },
                {
                    img: require('../static/image/index/ipart02_4.png'),
                    name: 'Testing equipment',
                    url: ''
                },

            ],
            industryList: [
                {
                    img: require('../static/image/index/ipart04_1.jpg'),
                    icon: require('../static/image/index/ipart04_icon1.png'),
                    title: 'Photovoltaic industry',
                    time: '1s'
                },
                {
                    img: require('../static/image/index/ipart04_2.jpg'),
                    icon: require('../static/image/index/ipart04_icon2.png'),
                    title: 'Automotive components',
                    time: '1.2s'
                },
                {
                    img: require('../static/image/index/ipart04_3.jpg'),
                    icon: require('../static/image/index/ipart04_icon3.png'),
                    title: 'Plastic machinery',
                    time: '1.4s'
                },
                {
                    img: require('../static/image/index/ipart04_4.jpg'),
                    icon: require('../static/image/index/ipart04_icon4.png'),
                    title: 'Valve',
                    time: '1.6s'
                },
                {
                    img: require('../static/image/index/ipart04_5.jpg'),
                    icon: require('../static/image/index/ipart04_icon5.png'),
                    title: 'Valve',
                    time: '1.8s'
                }
            ],
            process: [
                {
                    url: require('../static/image/index/ipart05_1.png'),
                    number: '01',
                    name: 'Phase 1',
                    con: 'Send us the design or manufacturing drawings for evaluation. Both 2D and 3D files are acceptable.',
                    time: '1s'
                },
                {
                    url: require('../static/image/index/ipart05_2.png'),
                    number: '02',
                    name: 'Phase 2',
                    con: 'Discuss the metal parts’ manufacturability and interchangeability to fix the final design.',
                    time: '1.2s'
                },
                {
                    url: require('../static/image/index/ipart05_3.png'),
                    number: '03',
                    name: 'Phase 3',
                    con: 'Make the quote, confirm the order, and start tooling, modeling, and mass production.',
                    time: '1.4s'
                },
                {
                    url: require('../static/image/index/ipart05_4.png'),
                    number: '04',
                    name: 'Phase 4',
                    con: 'Inspect the cargoes, package up, and arrange the delivery to you.',
                    time: '1.6s'
                }
            ],
            coreList: [
                {
                    time: '1s',
                    img: require('../static/image/index/ipart07_1.png'),
                    title: 'Quality',
                    text: 'Consistently offering quality products is the goal we will never stop pursuing'
                },
                {
                    time: '1.2s',
                    img: require('../static/image/index/ipart07_2.png'),
                    title: 'Technology',
                    text: 'Keep learning advanced technologies and applications, that will make you stronger'
                },
                {
                    time: '1.4s',
                    img: require('../static/image/index/ipart07_3.png'),
                    title: 'Services ',
                    text: '\n' +
                        'Quick response and effective communication implemented with a solution'
                },
                {
                    time: '1.6s',
                    img: require('../static/image/index/ipart07_4.png'),
                    title: 'Talents',
                    text: 'Team coordination is the foundation. Talents are the future. Both valued'
                }
            ]
        }
    },
    created() {
        this.$nextTick(()=>{
            if($('.ipart03 .content').length > 0){
                let _this = this
                var numberBox = $('.ipart03 .content'),//获取父元素
                    scrollHeight = numberBox.offset().top - $(window).height() + 160;//距文档顶部距离-屏幕高度+自身高度=滚动条滚去这个高度正好出现在屏幕中

                //主变化函数changeGo
                var changeGo = function() {
                    if ($(window).scrollTop() -$(window).height()/2 > scrollHeight) {
                        _this.startAnimate()
                        $(window).off('scroll', changeGo);//解除事件绑定
                    }
                }
                $(window).on('scroll', changeGo);
            }

        })
        //首页banner接口
        let _this = this
        this.$http.get(this.$API.getBannerByChannelId + '?id=1483422382099542017').then((res) => {
            if (res.data.code === 200) {
                this.ibannerList = res.data.result
                setTimeout(function () {
                    _this.imgHeight = $('.ibanner_img').height() + 'px'
                }, 2000)

                window.onresize = function temp() {
                    // 通过点语法获取img的height属性值
                    _this.imgHeight = $('.ibanner_img').height() + 'px'
                }
            }
        })

        // 获取栏目接口
        this.$http.get(this.$API.getChannelList).then((res) => {
            if (res.data.code === 200) {
                if (res.data.result.length > 1) {
                    document.title = res.data.result[0].seoTitle
                    this.productList[0].url = res.data.result[1].childList[0].url
                    this.productList[0].id = res.data.result[1].childList[0].id
                    this.productList[0].name = res.data.result[1].childList[0].name
                    this.productList[1].url = res.data.result[1].childList[1].url
                    this.productList[1].id = res.data.result[1].childList[1].id
                    this.productList[1].name = res.data.result[1].childList[1].name
                    this.productList[2].url = res.data.result[1].childList[2].url
                    this.productList[2].id = res.data.result[1].childList[2].id
                    this.productList[2].name = res.data.result[1].childList[2].name
                    this.productList[3].url = res.data.result[1].childList[3].url
                    this.productList[3].id = res.data.result[1].childList[3].id
                    this.productList[3].name = res.data.result[1].childList[3].name

                    this.industryList[0].url = res.data.result[2].childList[0].url
                    this.industryList[0].id = res.data.result[2].childList[0].id
                    this.industryList[0].name = res.data.result[2].childList[0].name

                    this.industryList[1].url = res.data.result[2].childList[1].url
                    this.industryList[1].id = res.data.result[2].childList[1].id
                    this.industryList[1].name = res.data.result[2].childList[1].name

                    this.industryList[2].url = res.data.result[2].childList[2].url
                    this.industryList[2].name = res.data.result[2].childList[2].name
                    this.industryList[2].id = res.data.result[2].childList[2].id

                    this.industryList[3].url = res.data.result[2].childList[3].url
                    this.industryList[3].id = res.data.result[2].childList[3].id
                    this.industryList[3].name = res.data.result[2].childList[3].name

                    this.industryList[4].url = res.data.result[2].childList[4].url
                    this.industryList[4].id = res.data.result[2].childList[4].id
                    this.industryList[4].name = res.data.result[2].childList[4].name
                }

            }
        })

        //获取首页内容接口
        this.$http.get(this.$API.getPageByChannelId + '?channelId=1483422382099542017').then((res) => {
            if (res.data.code === 200) {
                this.ipart02List = res.data.result[0]
                this.ipart04List = res.data.result[1]
            }
        })


        new WOW.WOW().init()
    },
    mounted() {
    },
    methods: {
        toContact() {
            this.$router.push({name: 'Contact Us', query: {toAnchor: 'contact_form', id: '1483421481972543490'}, params: {toAnchor: 'contact_form'}})
        },

        //第二部分跳转产品链接
        toProduct(item) {
            let idArr = item.url.split("#");
            let query = {id: item.id,name:item.name}
            if (idArr.length > 1) {
                query.catId = idArr[1]
            }
            this.$router.push({path: idArr[0], query: query});
        },
        //通过产品分类id 查询产品分类
        getProductCatList(item) {
            this.$http.get(`${this.$API.getProductCatList}?id=${item.catId}`).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.length > 0) {
                        item.childList = res.data.result[0].childList
                    }
                }
            });
        },

        //点击跳转到对应的产品列表页面
        toCheckProduct(item, link) {
            if (item.catId) {
                this.$router.push({path: '/industries/products', query: {id: item.id, catId: item.catId, childCatId: link.id}})

            } else {
                this.$router.push({path: link.url, query: {id: link.id, catId: link.catId}})
            }
        }
    }
}
</script>
<style lang="less">
@color: #51b953;
.ibanner {
    .el-carousel__button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.45);
        opacity: 1;
    }

    .el-carousel__indicators--horizontal {
        bottom: 100px
    }

    .el-carousel__indicator--horizontal {
        margin: 0 10px;
    }

    .el-carousel__indicator.is-active button {
        background-color: @color;
    }

    .el-carousel__item {
        .title {
            transition: 1s ease;
            transform: translateX(-200px);
        }

        .big_title {
            transition: 1s ease;
            transform: translateX(-200px);
        }

        .text {
            transition: 1s ease;
            transform: translateX(-200px);
        }

        //position: relative;
        &.is-active {
            .title {
                transform: translateX(0);
            }

            .big_title {
                transform: translateX(0);
            }

            .text {
                transform: translateX(0);
            }
        }
    }

    .el-carousel__arrow {
        width: 50px;
        height: 50px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

@media only screen and (max-width: 1366px) {
    .ibanner {
        .el-carousel__indicators--horizontal {
            bottom: 85px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .ibanner {
        .el-carousel__indicators--horizontal {
            bottom: 0;
        }
    }
}

@media only screen and (max-width: 760px) {
    .ibanner {
        .el-carousel__indicator--horizontal {
            margin: 0 5px;
        }

        .el-carousel__button {
            width: 12px;
            height: 12px;
        }
    }
}
</style>
<style scoped lang="less">
@color: #51b953;
.background {
    background-image: @color;
    background-image: linear-gradient(to right, #6ab62c, #329e4c);
}

.underline {
    content: '';
    position: absolute;
    width: 9.4%;
    height: 1px;
    background-color: @color;
    left: 50%;
    margin-left: -4.7%;
    bottom: -18px;

}

.home {
    background-color: #f2f2f2;
}

.common-intro {
    padding: 50px 5% 50px;
    text-align: center;

    .tit {
        font-size: 34px;
        color: #1b1f2e;
        margin-bottom: 35px;
        position: relative;

        &:after {
            .underline()
        }
    }

    .con {
        font-size: 16px;
        color: #3c3c3c;
        line-height: 1.6;
    }
}

.ibanner {
    img {
        width: 100%;
    }

    .intro {
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 36%;
        color: #fff;

        .title {
            font-size: 34px;
        }

        .big_title {
            font-size: 60px;
            font-weight: bold;
            margin: 10px 0 20px;
        }

        .text {
            width: 50%;
            font-size: 16px;
            line-height: 32px;
        }
    }
}

.ipart01 {
    margin-top: -65px;
    position: relative;
    z-index: 5;

    .content {
        background-color: #1b1f2e;
        padding: 35px;

        .text {
            font-size: 36px;
            color: #fff;
            width: 40%;
            margin: 0 15% 0 5%
        }

        .btn {
            margin-top: 2%;
            width: 25.4%;
            height: 68px;
            line-height: 68px;
            color: #ffffff;
            font-size: 25px;
            text-align: center;
            border-radius: 50px;
            cursor: pointer;
            .background();
        }
    }
}

.ipart02 {
    margin-bottom: 50px;

    img {
        width: 100%;
    }

    .content {
        .left_content {
            position: relative;
            width: 57.3%;

            .mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .cover {
                position: absolute;
                right: 0;
                top: 20%;
                width: 33%;
                padding-right: 10px;
                color: #ffffff;

                .title {
                    font-size: 33px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .con {
                    font-size: 28px;
                    margin: 15px 0;
                }

                .text {
                    font-size: 18px;
                    margin-bottom: 60px;
                }

                .btn {
                    display: inline-block;
                    font-size: 12px;
                    color: #101010;
                    background-color: #ffffff;
                    border-radius: 30px;
                    line-height: 46px;
                    padding: 0 30px;
                    cursor: pointer;
                    transition: .5s ease;

                    &:hover {
                        color: @color;
                    }
                }
            }
        }

        .right_content {
            width: 41.2%;

            .item {
                background-color: #ffffff;
                margin-bottom: 18px;
                position: relative;
                cursor: pointer;
                transition: .5s ease;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

                    .title {
                        transform: translateY(-60%);
                    }
                }

                .title {
                    position: absolute;
                    left: 5%;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 50%;
                    font-size: 30px;
                    color: #1e1e1e;
                    text-transform: uppercase;
                    transition: .5s ease;
                }

                .img {
                    width: 45%;
                }
            }
        }
    }
}

.ipart03 {
    background: url(../static/image/index/ipart03_bg.jpg) no-repeat center fixed;
    background-size: cover;

    .inner {
        padding: 70px 0;
        background: rgba(0, 2, 18, 0.6);
        text-align: center;

        .title {
            color: #ffffff;
            font-size: 34px;
            margin-bottom: 50px;
        }

        .content {
            .item {
                float: left;
                width: 25%;

                .img {
                    display: inline-block;
                    width: 64.5px;
                    height: 55px;
                    background-image: url('../static/image/index/ipart03_1.png');
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    transition: .5s ease;
                }

                .number {
                    margin-top: 10px;
                    font-size: 60px;
                    color: @color;
                }

                .text {
                    font-size: 16px;
                    color: #fff;
                    text-transform: capitalize;
                }

                &.item2 .img {
                    background-position: -64.5px 0;
                }

                &.item3 .img {
                    background-position: -129px 0;
                }

                &.item4 .img {
                    background-position: -193.5px 0;
                }

                &:hover {
                    .img {
                        transform: rotateY(180deg);
                    }
                }
            }
        }
    }


}

.ipart04 {
    background: #ffffff;
    padding-bottom: 80px;

    .content {
        .item {
            width: 20%;
            box-sizing: border-box;
            padding: 0 10px;
            perspective: 1000px;
            -webkit-transform: translate3d(0, 0, 0);

            &:hover {
                .img {
                    transform: rotateY(180deg);
                    opacity: 0;
                }

                .cover {
                    z-index: 1;
                    transform: rotateY(0);
                }
            }

            .inner {
                position: relative;
                overflow: hidden;
            }

            .img {
                position: relative;
                z-index: 1;
                width: 100%;
                transform: rotateY(0);
                transition: transform .5s cubic-bezier(.2, .85, .4, 1.275), opacity .5s ease;
                opacity: 1;

                img {
                    width: 100%;
                }
            }

            .cover {
                position: absolute;
                width: 100%;
                height: 100%;
                background: #f8f8f8;
                top: 0;
                left: 0;
                transform: rotateY(-180deg);
                z-index: -1;
                transition: transform .5s cubic-bezier(.2, .85, .4, 1.275);
                text-align: center;
                color: #3c3c3c;
                font-size: 14px;

                .box {
                    padding: 9% 10px;
                }

                .title {
                    font-size: 22px;
                    margin: 8px 0;
                }

                .text {
                    font-size: 14px;
                }

                .con {
                    font-size: 14px;

                    .sub_link {
                        font-size: 14px;
                        cursor: pointer;
                        margin: 2px 0;

                        &:hover {
                            color: @color;
                        }
                    }
                }

                .line {
                    display: inline-block;
                    width: 80%;
                    height: 1px;
                    background: #e5e5e5;
                    margin: 5px 0;
                }

                .btn {
                    cursor: pointer;
                    color: @color;
                    font-size: 16px;
                    line-height: auto;
                }
            }
        }
    }
}

.ipart05 {
    background: #eee;

    .title {
        font-size: 34px;
        color: #090909;
        padding: 50px 0 70px;
        text-align: center;
    }

    .content {
        .item {
            width: 25%;
            padding-bottom: 80px;

            &:hover {
                .number {
                    transform: translateY(-10px);
                }
            }

            .inner {
                padding: 0 20px;

                .number {
                    font-size: 48px;
                    line-height: 53px;
                    color: #969696;
                    transition: .5s ease;
                }

                .info {
                    margin-top: 25px;

                    .tit {
                        font-size: 24px;
                        color: #1f1f1f;
                        margin-bottom: 10px;
                    }

                    .con {
                        font-size: 14px;
                        color: #5a5a5a;
                        line-height: 1.6;
                    }
                }
            }
        }
    }
}

.ipart06 {
    background: url('../static/image/index/ipart06_bg.jpg') no-repeat center;
    background-size: cover;
}

@keyframes fusionSonarEffect {
    0% {
        opacity: .3;
    }
    40% {
        opacity: .5;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.ipart07 {
    background: #fff;
    padding-bottom: 80px;

    .content {
        .item {
            width: 25%;
            text-align: center;

            &:nth-child(odd) {
                .inner {
                    background: @color;
                    background: linear-gradient(#6db92b, #3ca347);

                    .title:after {
                        background: #fff;
                    }
                }
            }

            .inner {
                margin: 0 10px;
                background: #535d66;
                color: #fff;
                padding: 35px 30px;
                min-height: 310px;
                transition: .5s ease;

                &:hover {
                    .icon {
                        background: @color;

                        &:after {
                            pointer-events: none;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            content: "";
                            box-sizing: content-box;
                            top: 0;
                            left: 0;
                            padding: 0;
                            opacity: 0;
                            display: inline-block;
                            animation: fusionSonarEffect 1.3s ease-out 75ms;
                            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #51b953, 0 0 0 10px rgba(255, 255, 255, 0.5);
                        }

                    }
                }

                .icon {
                    position: relative;
                    width: 88px;
                    height: 88px;
                    display: inline-block;
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 50%;
                }

                .title {
                    margin: 25px 0 35px;
                    font-size: 30px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 110px;
                        height: 2px;
                        left: 50%;
                        margin-left: -55px;
                        bottom: -20px;
                        background: @color;
                    }
                }

                .text {
                    font-size: 18px;
                    line-height: 1.8;
                }
            }
        }
    }
}

.ipart08 {
    background: #eeeeee;

    .title {
        font-size: 34px;
        color: #131313;
        padding-top: 55px;
        padding-bottom: 20px;
        text-align: center;
    }

    .text {
        text-align: center;
        font-size: 16px;
        padding: 0 5%;
    }

    .content {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 1700px) {
    .ipart02 .content .left_content .cover {
        top: 23%;

        .text {
            margin-bottom: 30px;
        }
    }

    .ipart07 .content .item .inner {
        min-height: 400px;
    }
}

@media only screen and (max-width: 1440px) {
    .ipart04 .content .item .cover {
        .box {
            padding: 5% 10px;
        }

        .title {
            font-size: 20px;
            margin: 10px 0;
        }

        .line {
            margin: 10px 0;
        }
    }

}

@media only screen and (max-width: 1366px) {
    .ibanner {
        .intro {
            top: 28%
        }
    }

    .ipart01 .content {
        .text {
            font-size: 35px;
            width: 45%;
            margin: 0 10% 0 5%;
        }

        .btn {
            font-size: 20px;
        }
    }

}

@media only screen and (max-width: 1240px) {
    .ipart02 .content {
        .left_content .cover {
            top: 14%;
            width: 33%;

            .title {
                font-size: 30px;
            }

            .con {
                font-size: 25px;
            }

            .text {
                font-size: 18px;
            }
        }

        .right_content .item .title {
            font-size: 25px;
        }
    }

    .ipart04 .content .item {
        width: 50%;
        margin-bottom: 20px;
    }

    .ipart07 .content .item .inner {
        padding: 25px 10px;
        min-height: 350px;

        .text {
            font-size: 15px;
        }
    }

}

@media only screen and (max-width: 992px) {
    .common-intro {
        padding: 50px 5%;
    }

    .ibanner {
        .intro {
            .big_title {
                font-size: 47px;
            }

            .text {
                font-size: 14px;
                width: 65%;
            }
        }
    }

    .ipart01 {
        margin-top: 20px;

        .content {
            .text {
                font-size: 25px;
                margin: 0 3%;

            }

            .btn {
                width: 33%;
                font-size: 17px;
            }
        }

    }

    .ipart02 {
        .intro {
            padding: 30px 0 20px;

            .tit {
                font-size: 30px;

            }
        }

        .content {
            .left_content {
                width: 100%;
                margin-bottom: 20px;
            }

            .right_content {
                width: 100%;
            }
        }
    }

    .ipart07 .content .item {
        width: 50%;
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 760px) {
    .common-intro {
        padding: 20px 0;

        .tit {
            font-size: 20px;
            margin-bottom: 20px;

            &:after {
                bottom: -10px;
            }
        }

        .con {
            font-size: 14px;
        }
    }

    .ibanner {
        .intro {
            top: 10%;

            .title {
                font-size: 16px;
            }

            .big_title {
                font-size: 20px;
                margin: 5px 0 10px;
            }

            .text {
                //display:none;
                font-size: 14px;
                line-height: 20px;
                width: 100%
            }
        }

    }

    .ipart01 {
        .content {
            padding: 10px;

            .img {
                display: none;
            }

            .text {
                margin: 0;
                width: 100%;
                font-size: 18px;
            }

            .btn {
                width: 56%;
                margin-left: 22%;
                height: 46px;
                line-height: 46px;
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }

    .ipart02 {
        margin-bottom: 20px;

        .content {
            .left_content {
                .cover {
                    width: 94%;
                    top: 5%;

                    .title {
                        font-size: 20px;
                    }

                    .con {
                        font-size: 18px;
                    }

                    .text {
                        font-size: 14px;
                    }
                }
            }

            .right_content .item .title {
                font-size: 20px;
            }
        }
    }

    .ipart03 {
        .inner {
            padding: 20px 0;

            .title {
                font-size: 20px;
                margin-bottom: 20px;
            }

            .content {
                .item {
                    width: 50%;
                    margin-bottom: 20px;

                    .number {
                        font-size: 30px;
                    }

                    .text {
                        font-size: 14px;
                    }
                }
            }
        }

    }

    .ipart04 {
        padding-bottom: 20px;

        .content .item {
            width: 50%;
            padding: 0 1%;

            .cover {
                .icon {
                    img {
                        height: 30px;
                    }
                }

                .title {
                    font-size: 15px;
                    margin: 5px 0;
                }

                .con {
                    .sub_link {
                        margin: 0;
                        font-size: 12px;
                    }
                }

                .line {
                    margin: 2px 0;
                    display: none;
                }

                .btn {
                    font-size: 12px;
                }
            }
        }
    }

    .ipart05 {
        background: transparent;

        .title {
            font-size: 20px;
            padding: 20px 0;
        }

        .content {
            .item {
                width: 50%;
                padding: 0 10px 20px;
                min-height: 240px;

                .inner {
                    padding: 0;

                    .number {
                        font-size: 35px;
                    }

                    .info {
                        margin-top: 15px;

                        .tit {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }


    .ipart07 {
        padding-bottom: 20px;

        .content {
            .item {
                width: 50%;
                padding: 0 1%;

                .inner {
                    padding: 20px 10px;
                    margin: 0;
                    min-height: 234px;

                    .title {
                        font-size: 20px;
                        margin: 10px 0;

                        &:after {
                            width: 60px;
                            margin-left: -30px;
                            bottom: -10px;
                        }
                    }

                    .text {
                        font-size: 13px;
                    }

                    .icon {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }

    .ipart08 {
        .title {
            padding-top: 20px;
            font-size: 20px;
        }

        .content {
            padding: 20px 0;
        }
    }

}
</style>
