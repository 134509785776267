<template>
    <div class="processdetail">
        <xc-banner :banner-id="id" :name="name"/>
        <div class="processdetail01">
            <div class="wrap">
                <div class="name">{{ content_one.title }}</div>
                <div class="title">{{ content_one.subTitle }}</div>
                <div class="content fix">
                    <div class="text r" v-html="content_one.content"></div>
                    <div class="img imgZoom l">
                        <img :src="$SYS.IMG_PREFIX + content_one.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="processdetail02">
            <div class="wrap">
                <div class="title">{{ content_two.title }}</div>
                <div class="content fix">
                    <div class="text l" v-html="content_two.content"></div>
                    <div class="img imgZoom r">
                        <img :src="$SYS.IMG_PREFIX + content_two.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="processdetail03">
            <pro-carousel :content="content_five" :id="id" :cat-id="pid" :channel-id="catId" />
            <div class="view_more" @click="toProduct"><span>VIEW MORE</span></div>
        </div>
        <div class="processdetail04">
            <pro-text :item="infoList[0]" proAlign="img-left"/>
        </div>
        <div class="processdetail05">
            <pro-text :item="infoList[1]"/>
        </div>
        <div class="processdetail06">
            <center-text :item="content_four" />
        </div>
        <div class="processdetail07">
            <div class="wrap">
                <div class="title">
                    <div class="tit">{{ partner.title }}</div>
                    <div class="con">{{ partner.text }}</div>
                </div>
                <div class="content">
                    <partner />
                </div>
            </div>
        </div>
        <div class="processdetail08">
            <to-contact />
        </div>
    </div>
</template>

<script>
import XcBanner from "/src/components/xcBanner";
import ProCarousel from "/src/components/ProCarousel";
import ProText from "/src/components/ProText";
import CenterText from "/src/components/CenterText";
import Partner from "/src/components/Partner";
import ToContact from "/src/components/ToContact";

export default {
    name: "processdetail",
    components: {ToContact, Partner, CenterText, ProText, ProCarousel, XcBanner},
    data(){
        return {
            id:this.$route.query.id,
            catId:this.$route.query.catId,
            name:this.$route.query.name,
            pid:this.$route.query.pid,
            content_one: {},
            content_two: {},
            infoList: [],
            partner: {
                title: 'Our Partners',
                text: 'With our specialty in metal parts production, we have been serving many customers engaged in different industries like Home Appliances, Construction, Automobiles, Heavy Equipment, etc.'
            },
            content_four: {},
            content_five: {
                title: 'Products through custom CNC machining processes',
                proList: []
            }

        }
    },
    created() {
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
        this.$http.get(`${this.$API.getPageByChannelId}?channelId=${this.id}`).then(res => {
            if (res.data.code === 200) {
                if (res.data.result.length > 0) {
                    this.content_one = res.data.result[0]
                    this.content_two = res.data.result[1]
                    this.infoList = res.data.result.slice(2,4)
                    this.content_four = res.data.result[4]
                }
            }
        });
        this.getCraftListByCat(this.catId)

    },
    methods: {
        //工艺——产品列表接口
        getCraftListByCat(catId) {
            this.$http.get(this.$API.getCraftListByCat, {params: {'catId': catId}}).then(res => {
                if (res.data.code === 200) {
                    if (res.data.result.records.length > 0) {
                        this.content_five.proList = res.data.result.records

                    }
                }
            });

        },
        toProduct(){
            this.$router.push({path:'/capabilities/products', query: {id:this.id,catId:this.pid,childCatId: this.catId, name:this.name}});
        }
    }
}
</script>

<style scoped lang="less">
@color: #51b953;
@-webkit-keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

@keyframes warn-2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    5% {
        opacity: 8
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

.processdetail {
    .processdetail01 {
        margin: 60px 0;

        .name {
            text-align: center;
            color: #1d1d1d;
            font-size: 30px;
        }

        .title {
            text-align: center;
            font-size: 20px;
            color: #1d1d1d;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    .processdetail02 {
        background: #eeeeee;
        padding: 60px 0;

        .title {
            text-align: center;
            font-size: 30px;
            color: #1d1d1d;
            margin-bottom: 25px;
        }
    }

    .processdetail01 .content, .processdetail02 .content {
        .text {
            width: 62%;
            color: #5a5a5a;
            line-height: 1.6;
        }

        .img {
            width: 34%;
            position: relative;

            img {
                width: 100%;
            }
        }
    }

    .processdetail01 .cover, .processdetail02 .cover {
        position: absolute;
        left: 30px;
        bottom: 30px;

        .dot {
            display: inline-block;
            width: 65px;
            z-index: 5;
            height: 65px;
            vertical-align: middle;
            background: url(../../static/image/about/about_play.png) no-repeat center;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                width: 90px;
                height: 90px;
                -webkit-box-shadow: inset #6fba2a 0 0 40px;
                box-shadow: inset #6fba2a 0 0 40px;
                border-radius: 50%;
                top: -11.5px;
                left: -11.5px;
                opacity: 0;
                -webkit-animation: warn-2 2s ease-out 1.1s infinite;
                animation: warn-2 2s ease-out 1.1s infinite;
            }

            &:before {
                content: '';
                position: absolute;
                width: 65px;
                height: 65px;
                -webkit-box-shadow: inset #fff 0 0 40px;
                box-shadow: inset #fff 0 0 40px;
                border-radius: 50%;
                top: 0;
                left: 0;
                opacity: 0;
                -webkit-animation: warn 2s ease-out .1s infinite;
                animation: warn 2s ease-out .1s infinite;
            }
        }

        span {
            display: inline-block;
            width: 60%;
            color: #ffffff;
            font-size: 24px;
            margin-left: 20px;
            vertical-align: middle;
            line-height: 32px;
        }
    }

    .process03 {
        background-color: #eeeeee;
    }

    .processdetail07 {
        .title {
            text-align: center;

            .tit {
                font-size: 30px;
                color: #1d1d1d;
                margin-bottom: 15px;
            }

            .con {
                font-size: 17px;
                color: #5a5a5a;
            }

        }

        .content {
            margin: 40px 0 60px;
        }

    }
}

@media only screen and(max-width: 992px) {
    .processdetail .processdetail01 .cover span, .processdetail .processdetail02 .cover span {
        display: none;
    }

}

@media only screen and(max-width: 760px) {
    .processdetail {
        .processdetail01 {
            margin: 20px 0;

            .name {
                font-size: 14px;
            }

            .title {
                font-size: 20px;
            }
        }

        .processdetail01 .content .text,
        .processdetail02 .content .text {
            width:100%;
            margin-bottom: 20px;
        }
        .processdetail01 .content .img,
        .processdetail02 .content .img{
            width:100%;
        }
        .processdetail02{
            padding:20px 0 0;
            .title{
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
        .processdetail07 {
            .title {
                .tit{
                    font-size: 20px;
                }
                .con{
                    font-size: 14px;
                }
            }
            .content{
                margin:20px 0;
            }
        }
    }

}
</style>
