<template>
    <div class="protype">
        <div class="select">
            <div class="info">PRODUCTS CATEGORY</div>
            <div class="erji">
                <el-collapse accordion>
                    <el-collapse-item v-for="(item,i) in erjiList" :key="i">
                        <template slot="title">
                            <div class="title">
                        <span v-if="item.childList && item.childList.length > 0" class="icon">
                            <i class="iconfont jia icon-jiahao1"></i>
                            <i class="iconfont jian icon-jianhao"></i></span>
                                <span>{{ item.enName }}</span>
                            </div>
                        </template>
                        <div class="sanji" v-for="(sanji,i) in item.childList" :key="i" v-if="item.childList && item.childList.length > 0">
                            {{ sanji.enName }}
                        </div>
                    </el-collapse-item>
                </el-collapse>

            </div>
        </div>
        <div class="Brochures">
            <div class="tit">
                Brochures
            </div>
            <div class="con">
                View our 2021 financial prospectus brochure for an ease to read guide on all of the services offers
            </div>
            <div class="btn" @click="$router.push({'name':'About'})">COMPANY INFO</div>
            <div class="btn" @click="$router.push({'name':'Contact'})">CONTACT</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProType",
    data() {
        return {
            erjiList: [
                {
                    enName: 'Metal Stamping Parts',
                    id: "1482658370722283521",
                    childList: [
                        {
                            enName: 'Metal Stamping Parts',
                            id: "1482658370722283521",
                        },
                        {
                            enName: 'Metal Stamping Parts',
                            id: "1482658370722283521",
                        }
                    ]
                },
                {
                    enName: 'Deep Drawing Parts',
                    id: "1482658370722283521",
                    childList: [
                        {
                            enName: 'Press Bending Parts',
                            id: "1482658370722283521",
                        },
                        {
                            enName: 'Laser Cutting Parts',
                            id: "1482658370722283521",
                        }
                    ]
                },
                {
                    enName: 'Sand Casting Parts',
                    id: "1482658370722283521",
                    childList: []
                }
            ]
        }
    },
    methods: {}
}
</script>

<style scoped lang="less">
.protype {

    .select {
        .info {
            font-size: 24px;
            color: #1e1e1e;
            font-weight: bold;
        }


        .erji {
            margin: 20px 0;

            .title {
                background: #344e6c;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
                position: relative;
                width: 100%;

                .icon {
                    position: absolute;
                    left: 15px;
                    font-size: 16px;

                    .jian {
                        display: none;
                    }
                }

            }
            .is-active {
                .title{
                    .jia {
                        display: none;
                    }

                    .jian {
                        display: block;
                    }
                }
            }

            .sanji {
                margin-top: 10px;
                background: #7b8c9f;
                color: #fff;
                padding: 20px 40px;
                font-size: 18px;
            }
        }
    }

    .Brochures {
        background: #88d24e;
        padding: 38px 20px 25px;

        .tit {
            font-size: 24px;
            color: #fff;
        }

        .con {
            font-size: 16px;
            color: #fff;
            line-height: 1.6;
            margin: 25px 0;
        }

        .btn {
            background: #ffffff;
            color: #737373;
            line-height: 60px;
            cursor: pointer;
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 760px) {
    .protype {
        .select {
            .info {
                font-size: 20px;
            }
        }

        .Brochures {
            padding: 20px 10px;

            .tit {
                font-size: 18px;
            }

            .con {
                font-size: 14px;
                margin: 15px 0;
            }

            .btn {
                font-size: 14px;
                line-height: 45px;
            }
        }
    }
}

</style>
<style lang="less">
.protype{
    .el-collapse{border:none;}
    .el-collapse-item{
        margin-bottom: 20px;
    }
    .el-collapse-item__header {
        height: auto;
        line-height: inherit;
    }
    .el-collapse-item__arrow{display:none;}
    .el-collapse-item__content{
        padding-bottom: 0;}
}
</style>
