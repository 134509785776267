<template>
    <div class="center-text">
        <div class="wrap">
            <div class="title">{{ item.title }}</div>
            <div class="text" v-html="item.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CenterText",
    props: ['item']
}
</script>

<style scoped lang="less">
.center-text{
    margin:55px 0;
    .title {
        text-align: center;
        font-size: 30px;
        color:#1d1d1d;
        margin-bottom: 10px;
    }
    .text{
        font-size: 16px;
        color:#5a5a5a;
    }
}
@media only screen and(max-width: 760px) {
    .center-text {
        margin: 20px 0;
        .title{
            font-size: 20px;
        }
    }
}
</style>
