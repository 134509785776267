<template>
<div class="pro-carousel">
    <div class="wrap">
        <div class="title">{{content.title}}</div>
        <div class="content">
            <div class="swiper-wrapper">
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(item,i) in content.proList" :key="i">
                        <div class="img imgZoom" @click="toDetail(item)">
                            <div class="rect-100">
                                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>

            </div>
            <div class="swiper-pagination" slot="pagination"></div>
        </div>
    </div>
</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'    //在全局没引入，这里记得要！
import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
    name: "ProCarousel",
    components:{
        swiper,
        swiperSlide
    },
    props:['content','id','catId','channelId','noChild'],
    data(){
        return{
            swiperOption: {
                // 所有的参数同 swiper 官方 api 参数一样
                slidesPerView: '4',
                spaceBetween: 30,
                pagination: ".swiper-pagination", // 轮播图的点
                paginationClickable: '.swiper-pagination',
                // autoplay:3000,
            },

        }
    },
    created() {
        let baseType = this.$util.deviceType() === this.$SYS.DEVICE_TYPE_PC
        if(!baseType){
            this.swiperOption.slidesPerView = '2'
            this.swiperOption.spaceBetween=20
        }
    },
    methods:{
        toDetail(item){
            this.$router.push({path: `/capabilities/${this.id}/${this.catId}/${item.id}/${this.channelId}/${item.name}`,query:{noChild:this.noChild}})
        }
    }

}
</script>

<style scoped lang="less">
.pro-carousel {
    margin: 50px 0 70px;
    .title {
        font-size: 30px;
        color: #1d1d1d;
        margin-bottom: 35px;
        text-align: center;
    }

    .content {
        position:relative;
        .tit {
            text-align: center;
            color: #1e1e1e;
            font-size: 18px;
            padding: 15px 0;
        }
        .img{
            cursor:pointer;
            img{
                width:100%;
            }
        }
    }
}

@media only screen and(max-width: 760px) {
    .pro-carousel{
        margin:20px 0;
        .title{
            font-size:20px ;
            margin-bottom: 20px;
        }
    }
}
</style>
<style lang="less">
.pro-carousel {
    .swiper-container{
        width:100%;
    }
    .swiper-pagination{
        display:none;
        width:100%;
        text-align: center;
        bottom:-30px;
    }
    .swiper-pagination-clickable .swiper-pagination-bullet{
        margin:0 6px;
        width:10px;
        height:10px;
    }
    .swiper-pagination-bullet-active{
        background: #3ca347;
    }
}

</style>
