<template>
    <div class="CrastText" v-if="item.img">
        <div class="wrap fix">
            <div class="img imgZoom" v-if="item.img">
                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.title">
            </div>
            <div class="info">
                <div class="title fix">
                    <div class="icon l" v-if="item.icon">
                        <img :src="item.icon" :alt="item.title">
                    </div>
                    <div class="tit l">{{ item.title }}</div>
                </div>
                <div class="text" v-html="item.content"></div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CrastText",
    props: ['item']
}
</script>

<style scoped lang="less">
.CrastText {
    padding: 50px 0;

    .img {
        float: left;
        width: 29.6%;
    }

    .info {
        float: right;
        width: 62.4%;

        .title {
            margin-bottom: 34px;

            .icon {
                width: 76px;
                margin-right: 10px;
            }

            .tit {
                font-size: 30px;
                color: #1d1d1d;
                width: 80%;
            }
        }

        .text {
            font-size: 15px;
            color: #5a5a5a;
        }
    }

    &:nth-child(2n) {
        .img {
            float: right;
        }

        .info {
            float: left;
        }
    }
}
.odd .CrastText{
    .img {
        float: right;
    }

    .info {
        float: left;
    }
    &:nth-child(2n) {
        .img {
            float: left;
        }

        .info {
            float: right;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .CrastText .info .title .tit {
        font-size: 30px;
    }

}

@media only screen and (max-width: 760px) {
    .CrastText {
        padding: 20px 0 0;

        .img {
            width: 100%;
            margin-bottom: 20px;
        }

        .info {
            width: 100%;

            .title {
                margin-bottom: 20px;

                .icon {
                    width: 13%;
                    margin-right: 3%;
                }

                .tit {
                    width: 70%;
                    font-size: 18px;
                }
            }

            .text {
                font-size: 14px;
            }
        }
    }


}
</style>
