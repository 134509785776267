import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createLogger from "vuex/dist/logger";

/**
 * 注意事项: 本项目使用Vuex模块管理状态，各个业务的状态需要在./modules/modules下新增自己业务的Vuex模块,如:userinfo.js。新增模块之后会自动注册到Vuex上。
 * 所有对state修改必须通过mutations，mutations中只能进行同步操作，异步操作统一放到actions中。actions通过commit mutations的方式改变state
 * 为了防止不合规的操作，在开发模式开启了Vuex的严格模式，生产模式中不要启用。
*/

Vue.use(Vuex)
// 是否开发环境
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
	modules: {
		modules
	},
	strict: debug,
	plugins: debug ? [createLogger()] : []
})

export default store
