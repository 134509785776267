import {SysConfig} from './constants'
// 判断设备类型
const deviceType = () => {
    // phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i

    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return SysConfig.DEVICE_TYPE_MOBILE
    }
    return SysConfig.DEVICE_TYPE_PC
}
export default {
    deviceType
}
