<template>
    <div class="comment">
        <div class="wrap fix">
            <div class="intro l">
                <div class="title">
                    What Our Customers Say
                </div>
                <div class="text">
                    We’er serving
                    more.Because
                    of you
                </div>
            </div>
            <div class="swiper-wrapper r">
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(item,i) in picList" :key="i">
                        <div class="top_ fix">
                            <div class="img l">
                                <img :src="$SYS.IMG_PREFIX + item.img" :alt="item.name">
                            </div>
                            <div class="info l">
                                <div class="name">{{ item.name }}</div>
                                <div class="title">{{ item.position }}</div>
                            </div>
                        </div>
                        <div class="bottom_">
                            {{ item.content }}
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'    //在全局没引入，这里记得要！
import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
    name: "comment",
    data() {
        return {
            swiperOption: {
                // 所有的参数同 swiper 官方 api 参数一样
                pagination: ".swiper-pagination", // 轮播图的点
                paginationClickable: '.swiper-pagination',
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                loop: true, // 循环
                slidesPerView: 2,
                spaceBetween: 10,
                autoplay: 3000,

            },
            picList: []
        }
    },
    components: {
        swiper,
        swiperSlide
    },
    created() {
        this.$http.get(this.$API.getCustomerCommentList).then((res) => {
            if(res.data.code === 200){
                this.picList = res.data.result
            }
        })
    }
}
</script>
<style lang="less">
@color: #51b953;
.comment {
    .swiper-container{
        width:100%;
    }
    .swiper-pagination {
        position: absolute;
        width: 100%;
        bottom: -90px;
    }

    .swiper-pagination-bullet {
        opacity: 1;
        background: #fff;
        width: 10px;
        height: 10px;
        margin: 0 10px;
    }

    .swiper-pagination-bullet-active {
        background: @color;
    }
}

</style>
<style scoped lang="less">
@color: #51b953;
.comment {
    padding: 120px 0 190px;
    .intro {
        width: 25.3%;

        .title {
            font-size: 24px;
            color: @color;
            margin-bottom: 20px;
        }

        .text {
            font-size: 35px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1.1;
        }
    }

    .swiper-wrapper {
        width: 70%;
        color: #fff;
        position: relative;

        .top_ {
            margin-bottom: 35px;
            .img{
                width:80px;
            }
            .info {
                margin-left: 20px;
                margin-top: 20px;

                .name {
                    font-size: 22px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                .title {
                    color: @color;
                    text-transform: uppercase;
                }
            }
        }

        .bottom_ {
            font-size: 16px;
            line-height: 1.8;
            font-style: italic;
            margin-right: 50px;
        }
    }
}

@media only screen and(max-width: 1440px) {
    //.comment .intro .text {
    //    font-size: 35px;
    //}
}

@media only screen and(max-width: 1240px) {
    .comment .intro .text {
        font-size: 35px;
    }
}

@media only screen and(max-width: 992px) {
    .comment {
        .intro {
            width: 100%;
            margin-bottom: 20px;
        }

        .swiper-wrapper {
            width: 100%;
        }
    }
}

@media only screen and(max-width: 760px) {
    .comment {
        padding: 20px 0 40px;
        .intro {
            .title {
                font-size: 18px;
            }

            .text {
                font-size: 20px;
            }
        }

        .swiper-wrapper {
            .top_ {
                margin-bottom: 20px;
                .info {
                    margin-left: 0;
                    margin-top: 10px;

                    .name {
                        font-size: 18px;
                    }

                    .title {
                        font-size: 14px;
                    }
                }
            }

            .bottom_ {
                margin-right: 0;
                font-size: 14px;
            }
        }
        .swiper-pagination{
            bottom:-20px;
        }
    }

}


</style>
