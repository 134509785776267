<template>
    <div class="knowledge">
        <xc-banner :banner-id="id" name="Knowledge"/>
        <div class="knowledge01">
            <div class="wrap">
                <div class="title">
                    <div class="tit">FREQUENTLY ASKED QUESTIONS</div>
                    <div class="con">
                        <span>Answers to</span>
                        <span>All Your Questions</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="knowledge02">
            <div class="wrap fix">
                <div class="left l">
                    <div class="content" v-for="item in knowledge">
                        <div class="title" v-if="item.name && item.name !== ''">{{ item.name }}</div>
                        <ul>
                            <li>
                                <el-collapse>
                                    <el-collapse-item v-for="sub in item.answers">
                                        <template slot="title">
                                            <div class="tab_t">
                                                <div class="tit">
                                                    {{ sub.question }}
                                                    <div class="icon">
                                                        <i class="iconfont jia icon-jiahao1"> </i>
                                                        <i class="iconfont jian icon-jianhao"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="tab_c">
                                            <div class="box">
                                                <div class="txt" v-html="sub.answer">
                                                </div>
                                            </div>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="right r">
                    <div class="form">
                        <div class="info">
                            <div class="tit">Subscribe Us</div>
                            <div class="con">If you have more questions, you can subscribe to our push and we'll update you soon.</div>
                        </div>
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline-message="inlineMessage" class="demo-ruleForm">
                            <el-form-item prop="name">
                                <el-input v-model="ruleForm.name" placeholder="Your Name"></el-input>
                            </el-form-item>
                            <el-form-item prop="email">
                                <el-input v-model="ruleForm.email" placeholder="Your Email"></el-input>
                            </el-form-item>
                            <el-form-item prop="country">
                                <el-input v-model="ruleForm.country" placeholder="Your Country"></el-input>
                            </el-form-item>
                            <el-form-item prop="subject">
                                <el-input v-model="ruleForm.subject" placeholder="Your Subject"></el-input>
                            </el-form-item>
                            <el-form-item prop="subject">
                                <el-input type="textarea" v-model="ruleForm.message" placeholder="Your Message"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('ruleForm')">SEND MESSAGE</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="links">
                        <div class="title">Follow Us</div>
                        <div class="content">
                            <a :href="$SYS.LINK_FACEBOOK"><i class="iconfont icon-facebook-fill"></i></a>
                            <a :href="$SYS.LINK_TTWW"><i class="iconfont icon-ttww"></i></a>
                            <a :href="$SYS.LINK_LINKIN"><i class="iconfont icon-linkedin"></i></a>
                            <a :href="$SYS.LINK_INSTAGRAM"><i class="iconfont icon-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import xcBanner from "/src/components/xcBanner";

export default {
    name: "knowledge",
    components: {
        xcBanner
    },
    data() {
        let checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error('The mailbox can\'t be empty'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('Please enter the correct email format'))
                }
            }, 100)
        }
        return {
            id: this.$route.query.id,
            name: 'knowledge',
            title: '',
            knowledge: [],
            ruleForm: {
                name: '',
                email: '',
                country: '',
                subject: '',
                message: ''
            },
            inlineMessage: false,
            rules: {
                name: [
                    {required: true, message: 'Please enter your name', trigger: 'blur'},
                    {max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: 'Please enter your email', trigger: 'blur'},
                    {validator: checkEmail, trigger: 'blur'}

                ],
                country: [
                    {required: true, message: 'Please enter your country', trigger: 'blur'},
                    {max: 30, message: 'No more than 30 words', trigger: 'blur'}
                ],
                object: [
                    {required: false, message: 'Please enter your object', trigger: 'blur'},
                ],
                message: [
                    {required: false, message: 'Please enter message', trigger: 'blur'}
                ]
            },
            activeName: '1'
        }
    },
    created() {
        this.$http.get(this.$API.getQuestionAnswerList).then((res) => {
            if (res.data.code === 200) {
                this.knowledge = res.data.result
            }
        })
        document.title = this.name + '-Qingdao Zhangshi Group Co., Ltd'
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http.post(this.$API.postForm, {name: this.ruleForm.name, email: this.ruleForm.email, country: this.ruleForm.country, subject: this.ruleForm.subject, message: this.ruleForm.message}).then((res) => {
                        if (res.data.code === 200) {
                            this.$message.success({
                                message: res.data.result,
                                offset: 100
                            });
                        }
                    })
                } else {
                    this.$message.error({
                        message: 'error submit',
                        offset: 100
                    });
                    return false;
                }
            });
        },
    }
}
</script>
<style scoped lang="less">
@color: #6fba2a;
.knowledge01 {
    .title {
        padding-top: 50px;
        padding-bottom: 40px;

        .tit {
            color: @color;
            font-size: 18px;
            margin-bottom: 15px;
        }

        .con {
            font-size: 42px;
            color: #1f1f1f;
            font-weight: bold;
            line-height: 1.1;

            span {
                display: block;
            }
        }
    }
}

.knowledge02 {
    margin-bottom: 80px;

    .left {
        width: 64.5%;

        .content {
            .title {
                color: @color;
                font-size: 35px;
                margin: 0 0 30px;
            }

            ul {
                li {

                    .tab_t {
                        padding: 15px 20px;
                        background: #f2f2f2;
                        transition: .5s ease;
                        cursor: pointer;
                        width: 100%;

                        .tit {
                            position: relative;
                            font-size: 20px;
                            color: #1f1f1f;
                            padding-right: 30px;

                            .icon {
                                position: absolute;
                                right: 0;
                                top: 0;
                                font-weight: bold;

                                .iconfont {
                                    font-size: 22px;
                                }

                                .jian {
                                    display: none;
                                    color: #213c5a;
                                }
                            }
                        }
                    }

                    .tab_c {
                        background: #f2f2f2;

                        .box {
                            padding: 15px 20px;

                            .txt {
                                font-size: 15px;
                                color: #5a5a5a;
                                line-height: 1.5;
                            }
                        }
                    }

                    .is-active {
                        .tab_t {
                            background: @color;
                            background: linear-gradient(to right, #84e95d, #309d4d);

                            .tit {
                                color: #fff;

                                .icon {
                                    .jian {
                                        color: #fff;
                                        display: block;
                                    }

                                    .jia {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right {
        width: 27%;

        .form {
            background: #344e6c;
            padding: 45px 25px 23px 25px;

            .info {
                color: #fff;

                .tit {
                    font-size: 30px;
                    font-weight: bold;
                }

                .con {
                    font-size: 18px;
                    margin: 15px 0 25px;
                }
            }
        }

        .links {
            margin-top: 50px;
            background: #f2f2f2;
            padding: 30px 20px;

            .title {
                font-size: 30px;
                color: #344e6c;
                font-weight: bold;
                margin-bottom: 15px;
            }

            .content {
                a {
                    color: @color;
                    margin-right: 12px;

                    .iconfont {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1240px) {
    .knowledge02 {
        .right {
            width: 32%;
        }
    }

}


@media only screen and (max-width: 760px) {
    .knowledge01 {
        .title {
            padding: 20px 0;

            .tit {
                font-size: 16px;
            }

            .con {
                font-size: 20px;
            }
        }
    }

    .knowledge02 {
        margin-bottom: 20px;

        .left {
            width: 100%;

            .content {
                .title {
                    font-size: 20px;
                    margin: 20px 0;
                }

                ul li .tab_t .tit {
                    font-size: 15px;
                }

                ul li .tab_c .box .txt {
                    font-size: 14px;
                }
            }
        }

        .right {
            width: 100%;

            .form {
                padding: 20px 10px;

                .info {
                    .tit {
                        font-size: 20px;
                    }

                    .con {
                        font-size: 14px;
                    }
                }
            }

            .links {
                margin-top: 20px;
                padding: 20px 10px;

                .title {
                    font-size: 20px;
                }
            }
        }
    }

}
</style>
<style lang="less">
@color: #6fba2a;
.knowledge02 {
    .el-input__inner {
        line-height: 50px;
        height: 50px;
        border-radius: 0;
    }

    .el-collapse-item__content {
        padding-bottom: 0;
    }

    .el-collapse-item__arrow {
        display: none;
    }

    .el-collapse {
        border: none;
    }

    .el-collapse-item {
        margin-bottom: 20px;
    }

    .el-textarea__inner {
        border-radius: 0;
    }

    .el-collapse-item__header {
        height: auto;
        line-height: inherit;
    }

    .el-button {
        width: 100%;
        border: none;
        border-radius: 0;
        background: @color;
        display: block;
        padding: 0;
        line-height: 50px;
        height: 50px;
        font-size: 15px;
        transition: .5s ease;

        &:focus, &:hover {
            background: #3ca347;
        }
    }

}

@media only screen and (max-width: 760px) {
    .knowledge02 {
        .el-input__inner {
            line-height: 40px;
            height: 40px;
        }

        .el-button {
            line-height: 40px;
            height: 40px;
        }
    }

}
</style>
